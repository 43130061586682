import styles from "./Statuses.module.css";

interface localProps {
    titleLabel: string;
    statuses: { label: string; color: string }[];
}

export default function Statuses(props: localProps) {
    return (
        <div className={styles.content}>
            <div className={styles.header}>
                <span>{props.titleLabel}:</span>
            </div>
            {props.statuses.map((x) => {
                return (
                    <div key={x.label} className={styles.item}>
                        <div className={styles.color} style={{ backgroundColor: x.color }}></div>
                        {x.label}
                    </div>
                );
            })}
        </div>
    );
}
