import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Pagination.module.css";

interface localProps {
    page: number;
    pages?: number;
    change: (n: number) => void;
    paddingRight?: string;
    height?: string;
}

export default function Pagination({ page, pages = 1, change, paddingRight = "0", height = "0" }: localProps) {
    const handleDecrease = () => {
        if (page > 1) change(page - 1);
    };

    const handleIncrease = () => {
        if (page === pages) return;
        change(page + 1);
    };

    if (pages === 1) return <div style={{ paddingRight: paddingRight, height: height }} className={styles.content} />;

    return (
        <div style={{ paddingRight: paddingRight, height: height }} className={styles.content}>
            <div
                onClick={handleDecrease}
                className={`${page > 1 ? styles.icon_wrapper : styles.icon_wrapper_disabled} ${styles.icon_wrapper_left}`}
                style={{ width: height, height: height }}
            >
                {page > 1 && (
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={faArrowLeft}
                        style={{ width: `calc(${height} - 6px)`, height: `calc(${height} - 6px)` }}
                    />
                )}
            </div>

            <div
                className={styles.page_wrapper}
                style={{ width: `calc(1.5*${height})`, height: height, padding: `calc(${height}/4) 0` }}
            >
                {`${page} / ${pages}`}
            </div>

            <div
                onClick={handleIncrease}
                className={`${page < pages ? styles.icon_wrapper : styles.icon_wrapper_disabled} ${styles.icon_wrapper_right}`}
                style={{ width: height, height: height }}
            >
                {page < pages && (
                    <FontAwesomeIcon
                        className={styles.icon}
                        icon={faArrowRight}
                        style={{ width: `calc(${height} - 6px)`, height: `calc(${height} - 6px)` }}
                    />
                )}
            </div>
        </div>
    );
}
