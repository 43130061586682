import { useTranslation } from "react-i18next";
/* import { faBars, faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons"; */
import style from "./PopUpConfirmation.module.css";

interface localProps {
    action1: () => void;
    action2: () => void;
    action3: () => void;
}

export default function PopUpConfirmation(props: localProps) {
    const { t } = useTranslation("common", { keyPrefix: "popup" });
    return (
        <>
            <div className={style.container}>
                <div className={style.buttons_container}>
                    <div style={{ color: "#00b386", borderBottom: "2px solid #002C7D" }} className={style.title}>
                        <h3>{t("commissionCreated")}</h3>
                    </div>

                    {/* <Button width='250px' label={'Przejdź do zleceń'} />
                        <Button width='250px' label={'Nowe zlecenie w tej grupie'} />
                        <Button width='250px' label={'Dodaj nowe zlecenie w osobnej '} /> */}
                    <div className={style.button} onClick={props.action1}>
                        {/* <FontAwesomeIcon
                        className={style.icon}
                        icon={faBriefcase}
                    /> */}
                        {t("goToCommissions")}
                    </div>
                    <div className={style.button} onClick={props.action2}>
                        {" "}
                        {t("newGroup")}
                    </div>
                    <div className={style.button} onClick={props.action3} style={{ border: "0" }}>
                        {" "}
                        {t("newCommission")}{" "}
                    </div>
                </div>
            </div>
        </>
    );
}
