import backend, { QueryParams } from "api/backend";
import { AuditorCoWithAddress, AuditorCoWithContracts } from "api/entities/auditorCo";
import { AuditorCoBase, AuditorCoCreate, AuditorCoEdit } from "api/entities/bases/auditorCo";
import { AuditorCoAddressFilter, AuditorCoFilter } from "api/filters/auditor_co";

/**
 *  ## Object containing functions that fetch API endpoints related to service companies
 *  ### **_auditorCoApiService_** functions:
        fetchAuditorCompanies:
            (q?: QueryParams<AuditorCoBase[], AuditorCoFilter>)
                => Promise<backendResponse<AuditorCoBase[], AuditorCoFilter>>
    <br/>
        fetchAuditorCompany:
            (id: string [UUID])
                => Promise<backendResponse<AuditorCoBase, undefined>>
    <br/>
        fetchAuditorCompaniesWithAddresses:
            (q?: QueryParams<AuditorCoWithAddress[], AuditorCoAddressFilter>)
                => Promise<backendResponse<AuditorCoWithAddress[], AuditorCoAddressFilter>>
    <br/>
        fetchAuditorCompanyWithAddress:
            (id: string [UUID])
                => Promise<backendResponse<AuditorCoWithAddress, undefined>>
    <br/>
        fetchAuditorCompaniesWithContracts:
            (q?: QueryParams<AuditorCoWithContracts[], AuditorCoFilter>)
                => Promise<backendResponse<AuditorCoWithContracts[], AuditorCoFilter>>
    <br/>
        fetchAuditorCompanyWithContracts:
            (id: string [UUID])
                => Promise<backendResponse<AuditorCoWithContracts, undefined>>
    <br/>
        registerAuditorCompany:
            (comp: AuditorCoCreate)
                => Promise<backendResponse<AuditorCoBase, undefined>>
    <br/>
        removeAuditorCompany:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editAuditorCompany:
            (id: string [UUID], edit: AuditorCoEdit)
                => Promise<backendResponse<AuditorCoBase, undefined>>
 */
export const auditorCoApiService = {
    fetchAuditorCompanies: (q?: QueryParams<AuditorCoBase[], AuditorCoFilter>) => {
        return backend.get(`/auditor-companies`, q);
    },
    fetchAuditorCompany: (id: string) => {
        return backend.get<AuditorCoBase>(`/auditor-company/${id}`);
    },
    fetchAuditorCompaniesWithAddresses: (q?: QueryParams<AuditorCoWithAddress[], AuditorCoAddressFilter>) => {
        return backend.get(`/auditor-companies/address`, q);
    },
    fetchAuditorCompanyWithAddress: (id: string) => {
        return backend.get<AuditorCoWithAddress>(`/auditor-company/address/${id}`);
    },
    fetchAuditorCompaniesWithContracts: (q?: QueryParams<AuditorCoWithContracts[], AuditorCoFilter>) => {
        return backend.get(`/auditor-companies/contracts`, q);
    },
    fetchAuditorCompanyWithContracts: (id: string) => {
        return backend.get<AuditorCoWithContracts>(`/auditor-company/contracts/${id}`);
    },
    registerAuditorCompany: (comp: AuditorCoCreate) => {
        return backend.post<AuditorCoBase>(`/auditor-company`, comp);
    },
    removeAuditorCompany: (id: string) => {
        return backend.delete(`/auditor-company/${id}`);
    },
    editAuditorCompany: (id: string, edit: AuditorCoEdit) => {
        return backend.put<AuditorCoBase>(`/auditor-company/${id}`, edit);
    },
};
