import backend, { QueryParams } from "api/backend";
import { ServiceBase, ServiceCreate } from "api/entities/bases/service";
import { ServiceFilter } from "api/filters/service";

/**
 *  ## Object containing functions that fetch API endpoints related to services
 *  ### **_serviceApiService_** functions:
        fetchServices:
            (q?: QueryParams<ServiceBase[], ServiceFilter>)
                => Promise<backendResponse<ServiceBase[], ServiceFilter>>
    <br/>
        fetchService:
            (id: string [UUID])
                => Promise<backendResponse<ServiceBase, undefined>>
    <br/>
        fetchServicesAvailableForContract:
            (contract_id: string [UUID], q?: QueryParams<ServiceBase[]>)
                => Promise<backendResponse<ServiceBase[], undefined>>
    <br/>
        registerService:
            (service: ServiceCreate)
                => Promise<backendResponse<ServiceBase, undefined>>
    <br/>
        removeService:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
 */
export const serviceApiService = {
    fetchServices: (q?: QueryParams<ServiceBase[], ServiceFilter>) => {
        return backend.get(`/services`, q);
    },
    fetchServicesAvailableByCompany: (id: string, q?: QueryParams<ServiceBase[], ServiceFilter>) => {
        return backend.get(`/services/available/dispatcher-company/${id}`, q);
    },
    fetchService: (id: string) => {
        return backend.get<ServiceBase>(`/service/${id}`);
    },
    fetchServicesAvailableForContract: (contract_id: string, q?: QueryParams<ServiceBase[], ServiceFilter>) => {
        return backend.get(`/services/contract/${contract_id}/available`, q);
    },
    registerService: (service: ServiceCreate) => {
        return backend.post<ServiceBase>(`/service`, service);
    },
    removeService: (id: string) => {
        return backend.delete(`/service/${id}`);
    },
};
