import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-GB";
import de from "date-fns/locale/de";
import pt from "date-fns/locale/pt";
import cs from "date-fns/locale/cs";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: "pl",
        supportedLngs: ["pl", "en", "de", "pt", "cs"],
        fallbackLng: false,

        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },

        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

export const getLocale = () => {
    if (i18n.language === "pl") return pl;
    else if (i18n.language === "en") return en;
    else if (i18n.language === "de") return de;
    else if (i18n.language === "pt") return pt;
    else if (i18n.language === "cs") return cs;
};

export default i18n;
