import backend, { QueryParams } from "api/backend";
import { CommissionGroupBase, CommissionGroupCreate } from "api/entities/bases/commission_group";
import { CommissionGroupWithCommissions } from "api/entities/commission_group";
import { CommissionGroupFilter } from "api/filters/commission_group";

export const commissionGroupApiService = {
    fetchCommissionGroups: (q: QueryParams<CommissionGroupWithCommissions[], CommissionGroupFilter>) => {
        return backend.get(`/commission-groups`, q);
    },
    fetchCommissionGroup: (id: string) => {
        return backend.get<CommissionGroupWithCommissions>(`/commission-group/${id}`);
    },
    registerCommissionGroup: (payload: CommissionGroupCreate) => {
        return backend.post<CommissionGroupBase>(`/commission-group`, payload);
    },
};
