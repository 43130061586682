import backend, { QueryParams } from "api/backend";
import { UserEdit } from "api/entities/bases/common";
import { AdminCreate, UserBase, UserEditPassword } from "api/entities/bases/user";
import { LoginCheckResponse } from "api/entities/user";
import { UserFilter } from "api/filters/user";

/**
 *  ## Object containing functions that fetch API endpoints related to users
 *  ### **_userApiService_** functions:
        fetchUsers:
            (q?: QueryParams<UserBase[], UserFilter>)
                => Promise<backendResponse<UserBase[], UserFilter>>
    <br/>
        fetchUser:
            (id: string [UUID])
                => Promise<backendResponse<UserBase, undefined>>
    <br/>
        fetchCurrentUser:
            () => Promise<backendResponse<UserBase, undefined>>
    <br/>
        registerUser:
            (user: UserCreate)
                => Promise<backendResponse<UserBase, undefined>>
    <br/>
        registerAdmin:
            (admin: AdminCreate)
                => Promise<backendResponse<UserBase, undefined>>
    <br/>
        removeUser:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editUser:
            (id: string [UUID], edit: UserEdit)
                => Promise<backendResponse<UserBase, undefined>>
    <br/>
        editUserPassword:
            (id: string [UUID], currPwd: string, newPwd: string)
                => Promise<backendResponse<UserPwdEdit, undefined>>
    <br/>
        checkLoginAvailability:
            (login: string)
                => Promise<backendResponse<LoginCheckResponse, undefined>>
 */
export const userApiService = {
    fetchUsers: (q?: QueryParams<UserBase[], UserFilter>) => {
        return backend.get(`/users`, q);
    },
    fetchUser: (id: string) => {
        return backend.get<UserBase>(`/user/${id}`);
    },
    fetchCurrentUser: () => {
        return backend.get<UserBase>(`/user/Current`);
    },
    registerAdmin: (admin: AdminCreate) => {
        return backend.post<UserBase>(`/user/admin`, admin);
    },
    removeUser: (id: string) => {
        return backend.delete(`/user/${id}`);
    },
    editUser: (id: string, edit: UserEdit) => {
        return backend.put<UserBase>(`/user/${id}`, edit);
    },
    editUserPassword: (id: string, currPwd: string, newPwd: string) => {
        return backend.put<UserEditPassword>(`/user/edit-user-password/${id}`, {
            password: newPwd,
            old_password: currPwd,
        });
    },
    checkLoginAvailability: (login: string) => {
        return backend.get<LoginCheckResponse>(`/user/check-login/${login}`);
    },
};
