import styles from "./Button.module.css";

interface localProps {
    type?: "button" | "submit";
    click?: () => void;
    label?: string | null;

    className?: any;
    id?: any; // pass string to an id section in Button.module.css
    disabled?: boolean;
    height?: string;
    width?: string;
}

export default function Button({
    type = "button",
    click,
    label = "NULL",
    className,
    id,
    disabled = false,
    height,
    width,
}: localProps) {
    return (
        <button
            type={type ?? "button"}
            onClick={
                click ??
                (() => {
                    if (type !== "submit") console.log("button error");
                })
            }
            className={`${className ?? styles.button} ${styles[id]}`}
            disabled={disabled}
            style={{ height: height, width: width }}
        >
            {label}
        </button>
    );
}
