import { GenericTypeObject } from "api/backend";
import { useDataContext } from "./DataProvider";
import { ContextError } from "./ContextError";
import { ReactElement, useEffect, useState } from "react";
import { EntityGroup } from "api/entities/bases";

interface DataGrouperProps<T, G, U, Q extends EntityGroup<U>> {
    gto: GenericTypeObject<T, G, U>;
    group_type: Q;
    group_func: (e: U) => { grp_id: string; ungrouped: boolean };
    children: (data: { group: Q; ungrouped: boolean }[]) => ReactElement;
}

export const DataGrouper = <T, G, U, Q extends EntityGroup<U>>({
    group_func,
    children,
}: DataGrouperProps<T, G, U, Q>) => {
    const dataContext = useDataContext<U[], G>();

    const [groups, setGroups] = useState<{ group: Q; ungrouped: boolean }[]>([]);

    useEffect(() => {
        let temp: { [k: string]: { data: U[]; ungrouped: boolean } } = {};
        let tempQ: { group: Q; ungrouped: boolean }[] = [];

        dataContext.data?.forEach((e) => {
            const grp = group_func(e);

            if (temp[grp.grp_id] !== undefined) {
                temp[grp.grp_id].data.push(e);
            } else {
                temp[grp.grp_id] = { data: [e], ungrouped: false };
            }
        });

        for (let q of Object.keys(temp)) {
            tempQ.push({
                group: {
                    group_id: q,
                    data: temp[q].data,
                } as Q,
                ungrouped: temp[q].ungrouped,
            });
        }

        setGroups(tempQ);
    }, [group_func, dataContext]);

    if (dataContext.isUndefined)
        return <ContextError consumerName="DataProvider.Grouper" providerName="DataProvider" />;
    return <>{children(groups)}</>;
};

export default DataGrouper;
