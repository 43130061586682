import backend, { QueryParams } from "api/backend";
import {
    ContractWithCompanies,
    ContractWithPricesAuditorDispatcherCompany,
    ContractWithPricesService,
} from "api/entities/contract";
import { ContractBase, ContractCreate, ContractEdit } from "api/entities/bases/contract";
import {
    ContractWithPricesServiceAuditorCompany,
    ContractWithPricesServiceDispatcherCompany,
} from "api/entities/contract";
import { ContractAuditorCoFilter, ContractCompaniesFilter, ContractDispatcherCoFilter } from "api/filters/contract";

/**
 *  ## Object containing functions that fetch API endpoints related to contracts
 *  ### **_contractApiService_** functions:
        fetchContracts:
            (q?: QueryParams<ContractBase[], ContractCompaniesFilter>)
                => Promise<backendResponse<ContractBase[], ContractCompaniesFilter>>
    <br/>
        fetchContract:
            (id: string [UUID])
                => Promise<backendResponse<ContractBase, undefined>>
    <br/>
        fetchContractsByDispatcherCoWithPricesAuditorCompany:
            (dispatcher_co_id: string [UUID], q?: QueryParams<ContractWithAuditorCompany[], ContractAuditorCoFilter>)
                => Promise<backendResponse<ContractWithAuditorCompany[], ContractAuditorCoFilter>>
    <br/>
        fetchContractsByAuditorCoWithPricesDispatcherCompany:
            (auditor_co_id: string [UUID], q?: QueryParams<ContractWithDispatcherCompany[], ContractDispatcherCoFilter>)
                => Promise<backendResponse<ContractWithDispatcherCompany[], ContractDispatcherCoFilter>>
    <br/>
        fetchContractsWithPriceAndService:
            (q?: QueryParams<ContractWithPricesService[], ContractCompaniesFilter>)
                => Promise<backendResponse<ContractWithPricesService[], ContractCompaniesFilter>>
    <br/>
        fetchContractWithPriceAndService:
            (id: string [UUID])
                => Promise<backendResponse<ContractWithPricesService, undefined>>
    <br/>
        fetchContractsWithAuditorDispatcherCompany:
            (q?: QueryParams<ContractWithCompanies[], ContractCompaniesFilter>)
                => Promise<backendResponse<ContractWithCompanies[], ContractCompaniesFilter>>
    <br/>
        fetchContractWithAuditorDispatcherCompany:
            (id: string [UUID])
                => Promise<backendResponse<ContractWithCompanies, undefined>>
    <br/>
        fetchContractsWithPriceAndAuditorDispatcherCompany:
            (q?: QueryParams<ContractWithPricesAuditorDispatcherCompany[], ContractCompaniesFilter>)
                => Promise<backendResponse<ContractWithPricesAuditorDispatcherCompany[], ContractCompaniesFilter>>
    <br/>
        fetchContractWithPriceAndAuditorDispatcherCompany:
            (id: string [UUID])
                => Promise<backendResponse<ContractWithPricesAuditorDispatcherCompany, undefined>>
    <br/>
        fetchContractByCompanies:
            (auditor_co_id: string [UUID], dispatcher_co_id: string [UUID])
                => Promise<backendResponse<ContractWithPricesService, undefined>>
    <br/>
        registerContract:
            (contract: ContractCreate)
                => Promise<backendResponse<ContractBase, undefined>>
    <br/>
        removeContract:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editContract:
            (id: string [UUID], edit: ContractEdit)
                => Promise<backendResponse<ContractBase, undefined>>
 */
export const contractApiService = {
    fetchContracts: (q?: QueryParams<ContractBase[], ContractCompaniesFilter>) => {
        return backend.get(`/contracts`, q);
    },
    fetchContract: (id: string) => {
        return backend.get<ContractBase>(`/contract/${id}`);
    },
    fetchContractsByDispatcherCoWithPricesAuditorCompany: (
        dispatcher_co_id: string,
        q?: QueryParams<ContractWithPricesServiceAuditorCompany[], ContractAuditorCoFilter>,
    ) => {
        return backend.get(`/contracts/dispatcher-company/${dispatcher_co_id}`, q);
    },
    fetchContractsByAuditorCoWithPricesDispatcherCompany: (
        auditor_co_id: string,
        q?: QueryParams<ContractWithPricesServiceDispatcherCompany[], ContractDispatcherCoFilter>,
    ) => {
        return backend.get(`/contracts/auditor-company/${auditor_co_id}`, q);
    },
    fetchContractsWithPriceAndService: (q?: QueryParams<ContractWithPricesService[], ContractCompaniesFilter>) => {
        return backend.get(`/contracts/prices-aqnd-service`, q);
    },
    fetchContractWithPriceAndService: (id: string) => {
        return backend.get<ContractWithPricesService>(`/contract/prices-and-service/${id}`);
    },
    fetchContractsWithAuditorDispatcherCompany: (q?: QueryParams<ContractWithCompanies[], ContractCompaniesFilter>) => {
        return backend.get(`/contracts/auditor-dispatcher-company`, q);
    },
    fetchContractWithAuditorDispatcherCompany: (id: string) => {
        return backend.get<ContractWithCompanies>(`/contract/auditor-dispatcher-company/${id}`);
    },
    fetchContractsWithPriceAndAuditorDispatcherCompany: (
        q?: QueryParams<ContractWithPricesAuditorDispatcherCompany[], ContractCompaniesFilter>,
    ) => {
        return backend.get(`/contracts/prices-and-auditor-dispatcher-company`, q);
    },
    fetchContractWithPriceAndAuditorDispatcherCompany: (id: string) => {
        return backend.get<ContractWithPricesAuditorDispatcherCompany>(
            `/contract/prices-and-auditor-dispatcher-company/${id}`,
        );
    },
    fetchContractByCompanies: (auditor_co_id: string, dispatcher_co_id: string) => {
        return backend.get<ContractWithPricesService>(`/contract/companies/${auditor_co_id}/${dispatcher_co_id}`);
    },
    registerContract: (contract: ContractCreate) => {
        return backend.post<ContractBase>(`/contract`, contract);
    },
    removeContract: (id: string) => {
        return backend.delete(`/contract/${id}`);
    },
    editContract: (id: string, edit: ContractEdit) => {
        return backend.put<ContractBase>(`/contract/${id}`, edit);
    },
};
