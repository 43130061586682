import { once } from "lodash";
import {
    Dispatch,
    ReactElement,
    SetStateAction,
    cloneElement,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import { ContextError, GeneralContextProps } from "./ContextError";
import { GenericTypeObject } from "api/backend";
import { useDataContext } from "./DataProvider";

interface DataStatusContextProps<U> extends GeneralContextProps {
    statuses: { [status: string]: { color: string; condition: (e: U) => boolean } };
    statusKeys: string[];
    active: { [s: string]: boolean };
    setActive: Dispatch<SetStateAction<{ [s: string]: boolean }>>;
}

export const createDataStatusContext = once(<U,>() =>
    createContext({ isUndefined: true } as DataStatusContextProps<U>),
);
export const useDataStatusContext = <U,>() => useContext(createDataStatusContext<U>());

interface DataStatusLabelProps<T, G, U> {
    gto: GenericTypeObject<T, G, U>;
    st: any;
    statusWrapperElement?: ReactElement;
    statusElement?: ReactElement;
}

const DataStatusLabel = <T, G, U>({
    st,
    statusWrapperElement = <div />,
    statusElement = <div />,
}: DataStatusLabelProps<T, G, U>) => {
    const statusContext = useDataStatusContext<U>();

    if (statusContext.isUndefined)
        return <ContextError consumerName="DataStatusLabel" providerName="DataStatusProvider" />;
    return (
        <>
            {cloneElement(statusWrapperElement, {
                key: "status_label_wrapper",
                children: <b>{st("")}:</b>,
            })}
            {statusContext.statusKeys.map((s) =>
                cloneElement(statusWrapperElement, {
                    key: "status_wrapper_" + s,

                    children: (
                        <>
                            {cloneElement(statusElement, {
                                style: {
                                    backgroundColor: statusContext.active[s]
                                        ? statusContext.statuses[s].color
                                        : "white",
                                },
                            })}
                            {st(`${s}`)}
                        </>
                    ),
                }),
            )}
        </>
    );
};

interface DataStatusProviderProps<T, G, U> {
    gto: GenericTypeObject<T, G, U>;
    statuses: { [status: string]: { color: string; condition: (e: U) => boolean; hide?: boolean } };
    children?: (
        statuses: { [st: string]: { color: string; condition: (e: U) => boolean; hide?: boolean } },
        active: { [l: string]: boolean },
    ) => any;
}

export const DataStatusProvider = <T, G, U>({ statuses, children }: DataStatusProviderProps<T, G, U>) => {
    const statusKeys = Object.keys(statuses);
    const [active, setActive] = useState<{ [s: string]: boolean }>({});

    const dataStatusContext = createDataStatusContext<U>();
    const dataContext = useDataContext<T, G, U>();

    useEffect(() => {
        let temp = {};

        statusKeys.forEach((s) => {
            temp = { ...temp, [s]: statuses[s].hide ? false : true };
        });

        setActive(temp);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let st: string[] = [];

        for (let s of statusKeys) {
            if (active[s]) {
                st.push(s);
            }
        }

        dataContext.changeQuery({
            statuses: st.length === statusKeys.length ? [] : st,
        });
        // eslint-disable-next-line
    }, [active]);

    return (
        <dataStatusContext.Provider
            value={{
                statuses: statuses,
                statusKeys: statusKeys,
                active: active,
                setActive: setActive,
            }}
        >
            {children && children(statuses, active)}
        </dataStatusContext.Provider>
    );
};

DataStatusProvider.Label = DataStatusLabel;
