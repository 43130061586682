import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import styles from "../Listings.module.css";

import api from "api";
import { ContractWithPricesAuditorDispatcherCompany } from "api/entities/contract";
import { DataList, DataProvider, TitleProvider } from "commons/DataManagement";
import { PriceWithService } from "api/entities/price";
import { ContractCompaniesFilter } from "api/filters/contract";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

const SecondaryRow = (props: { data: ContractWithPricesAuditorDispatcherCompany; expand: boolean }) => {
    const nodeRef = useRef(null);
    const [fetchedData, setFetchedData] = useState<PriceWithService[]>([]);
    const { handleErrors } = useErrorHandling();

    useEffect(() => {
        if (props.expand) {
            try {
                api.fetchPricesByContract(props.data.id, {
                    order_by: "service_name",
                }).then((x) => {
                    handleErrors(x.error);
                    setFetchedData(x.data as PriceWithService[]);
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [props, handleErrors]);

    const navigate = useNavigate();
    const { t } = useTranslation("adminView", { keyPrefix: "contracts.handles" });

    // Po kliknieciu wyświetlenie pracowników firmy
    const handleNewPrice = (contract_id: string, comp_1: string, comp_2: string) => {
        navigate("/admin/register", {
            state: {
                id: "10",
                contract_id: contract_id,
                comp_1: comp_1,
                comp_2: comp_2,
            },
        });
    };

    return (
        <CSSTransition
            in={props.expand}
            nodeRef={nodeRef}
            timeout={300}
            classNames={{
                enter: styles.csstrEnter,
                enterActive: styles.csstrEnterActive,
                exit: styles.csstrExit,
                exitActive: styles.csstrExitActive,
            }}
            unmountOnExit
            appear
        >
            <td ref={nodeRef} className={styles.td_exp} colSpan={6}>
                <div className={styles.td_exp_content}>
                    <div className={styles.td_exp_heading}>
                        <div className={styles.td_exp_services}>
                            {t("pricing")}:
                            {fetchedData.map((x) => {
                                return (
                                    <div className={styles.td_exp_price} key={x.service_id}>
                                        {" "}
                                        {x.service?.name} - {x.price} zł.{" "}
                                    </div>
                                );
                            })}
                        </div>
                        <div className={styles.td_exp_heading_content}>
                            <div>
                                <button
                                    onClick={() => {
                                        handleNewPrice(props.data.id, props.data.auditor_company.company_name, props.data.dispatcher_company.company_name);
                                    }}
                                    className={styles.td_exp_button}
                                >
                                    {t("addService")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </CSSTransition>
    );
};

export const Contracts = () => {
    const location = useLocation();

    const { t } = useTranslation("adminView", { keyPrefix: "contracts" });
    const headerTranslation = useTranslation("adminView", {
        keyPrefix: "contracts.data",
    });

    const filterRef = useRef({
        auditor_co_name: location.state?.auditor_company ?? "",
        dispatcher_co_name: location.state?.dispatcher_company ?? "",
    } as ContractCompaniesFilter);

    return (
        <>
            <TitleProvider title={`${t("title")} | Raily Marketplace`} />
            <div className={styles.content}>
                <div className={styles.row}>
                    <h1>{t("title")}</h1>
                </div>
                <DataProvider
                    fetchFun={api.fetchContractsWithPriceAndAuditorDispatcherCompany}
                    fetchArgs={[]}
                    query={{
                        order_by: "auditor_company_name" as keyof ContractWithPricesAuditorDispatcherCompany,
                    }}
                >
                    {(gto) => {
                        return (
                            <>
                                <div className={styles.table_wrapper}>
                                    <DataList
                                        gto={gto}
                                        keyGenerator={(e) => e.id}
                                        labelGenerator={{
                                            auditorCo: {
                                                label: (e) => e.auditor_company.company_name,
                                                column: "auditor_company_name",
                                                filter: {
                                                    key: "auditor_co_name",
                                                    type: "string",
                                                },
                                            },
                                            auditorCoTaxNo: {
                                                label: (e) => e.auditor_company.tax_id,
                                                filter: {
                                                    key: "auditor_co_nip",
                                                    type: "string",
                                                },
                                            },
                                            auditorCoRegon: {
                                                label: (e) => e.auditor_company.regon,
                                                filter: {
                                                    key: "auditor_co_regon",
                                                    type: "string",
                                                },
                                            },
                                            dispatcherCo: {
                                                label: (e) => e.dispatcher_company.company_name,
                                                column: "dispatcher_company_name",
                                                filter: {
                                                    key: "dispatcher_co_name",
                                                    type: "string",
                                                },
                                            },
                                            dispatcherCoTaxNo: {
                                                label: (e) => e.dispatcher_company.tax_id,
                                                filter: {
                                                    key: "dispatcher_co_nip",
                                                    type: "string",
                                                },
                                            },
                                            dispatcherCoRegon: {
                                                label: (e) => e.dispatcher_company.regon,
                                                filter: {
                                                    key: "dispatcher_co_regon",
                                                    type: "string",
                                                },
                                            },
                                        }}
                                        defaultOrder="auditor_company_name"
                                        tableWrapperElement={<table className={styles.table} />}
                                        headerElement={
                                            <DataList.Header
                                                gto={gto}
                                                translation={headerTranslation.t}
                                                headerElement={<thead className={styles.thead} />}
                                                labelElement={<th scope="col" className={styles.th} />}
                                                filterElement={<input className={styles.input} />}
                                                filterRef={filterRef}
                                            />
                                        }
                                        dataRowElement={<tr className={styles.tr} />}
                                        dataLabelElement={<td className={styles.td} />}
                                    >
                                        {(data, expand, key) => (
                                            <tr className={styles.tr_exp} key={key}>
                                                <SecondaryRow data={data} expand={expand} />
                                            </tr>
                                        )}
                                    </DataList>
                                </div>
                                <div className={styles.row_items}>
                                    <DataProvider.Pagination gto={gto} />
                                </div>
                            </>
                        );
                    }}
                </DataProvider>
            </div>
        </>
    );
};

export default Contracts;
