import backend, { QueryParams } from "api/backend";
import {
    CommissionForAuditorCompany,
    CommissionForDispatcherCompany,
    CommissionWithDispatcherAddressService,
} from "api/entities/commission";
import { CommissionCreate, CommissionEdit, CommissionBase } from "api/entities/bases/commission";
import { CommissionFilter } from "api/filters/commission";
import { CommissionGroupBase } from "api/entities/bases/commission_group";

/**
 *  ## Object containing functions that fetch API endpoints related to commissions
 *  ### **_commissionApiService_** functions:
        fetchCommissions:
            (q?: QueryParams<CommissionWithDispatcherAddressService[], CommissionFilter>)
                => Promise<backendResponse<CommissionWithDispatcherAddressService[], CommissionFilter>>
    <br/>
        fetchCommission:
            (id: string [UUID])
                => Promise<backendResponse<CommissionWithDispatcherCompany, undefined>>
    <br/>
        fetchCommissionsForDispatcherCompany:
            (dispatcher_co_id: string [UUID], q?: QueryParams<CommissionForDispatcherCompany[], CommissionFilter>)
                => Promise<backendResponse<CommissionForDispatcherCompany[], CommissionFilter>>
    <br/>
        fetchCommissionsForAuditorCompany:
            (auditor_co_id: string [UUID], q?: QueryParams<CommissionForAuditorCompany[], CommissionFilter>)
                => Promise<backendResponse<CommissionForAuditorCompany[], CommissionFilter>>
    <br/>
        fetchCommissionForAuditorCompany:
            (commission_id: string [UUID], auditor_co_id: string [UUID])
                => Promise<backendResponse<CommissionForAuditorCompany, undefined>>
    <br/>
        registerCommission:
            (commission: CommissionCreate)
                => Promise<backendResponse<CommissionBase, undefined>>
    <br/>
        removeCommission:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editCommission:
            (id: string [UUID], edit: CommissionEdit)
                => Promise<backendResponse<CommissionBase, undefined>>
 */
export const commissionApiService = {
    fetchCommissions: (q?: QueryParams<CommissionWithDispatcherAddressService[], CommissionFilter>) => {
        return backend.get(`/commissions`, q);
    },
    fetchCommission: (id: string) => {
        return backend.get<CommissionForDispatcherCompany>(`/commission/${id}`);
    },
    fetchCommissionsForDispatcherCompany: (
        dispatcher_co_id: string,
        q?: QueryParams<CommissionForDispatcherCompany[], CommissionFilter>,
    ) => {
        return backend.get(`/commissions/dispatcher-company/${dispatcher_co_id}`, q);
    },
    fetchCommissionsForAuditorCompany: (
        auditor_co_id: string,
        q?: QueryParams<CommissionForAuditorCompany[], CommissionFilter>,
    ) => {
        return backend.get(`/commissions/auditor-company/${auditor_co_id}`, q);
    },
    fetchCommissionForAuditorCompany: (commission_id: string, auditor_co_id: string) => {
        return backend.get<CommissionForAuditorCompany>(
            `/commission/${commission_id}/auditor-company/${auditor_co_id}`,
        );
    },
    fetchCommissionsFinishedForDispatcherCompany: (
        co_id: string,
        q?: QueryParams<CommissionWithDispatcherAddressService[], CommissionFilter>,
    ) => {
        return backend.get(`/commissions/finished`, {
            ...q,
            additional_query: { dispatcher_co_id: co_id },
        } as QueryParams<CommissionWithDispatcherAddressService[], CommissionFilter>);
    },
    fetchCommissionsCanceledForDispatcherCompany: (
        co_id: string,
        q?: QueryParams<CommissionWithDispatcherAddressService[], CommissionFilter>,
    ) => {
        return backend.get(`/commissions/canceled`, {
            ...q,
            additional_query: { dispatcher_co_id: co_id },
        } as QueryParams<CommissionWithDispatcherAddressService[], CommissionFilter>);
    },
    fetchCommissionsFinishedForAuditorCompany: (
        co_id: string,
        q?: QueryParams<CommissionForAuditorCompany[], CommissionFilter>,
    ) => {
        return backend.get(`/commissions/finished/auditor-company/${co_id}`, q);
    },
    fetchCommissionsCanceledForAuditorCompany: (
        co_id: string,
        q?: QueryParams<CommissionForAuditorCompany[], CommissionFilter>,
    ) => {
        return backend.get(`/commissions/canceled/auditor-company/${co_id}`, q);
    },

    fetchCommissionCountForDispatcherCompany: () => {
        return backend.get(`/commissions/states/dispatcher`);
    },

    fetchCommissionCountForAuditorCompany: () => {
        return backend.get(`/commissions/states/operator`);
    },

    registerCommission: (commission: CommissionCreate) => {
        return backend.post<CommissionBase>(`/commission`, commission);
    },
    removeCommission: (id: string) => {
        return backend.delete(`/commission/${id}`);
    },
    editCommission: (id: string, edit: CommissionEdit) => {
        return backend.put<CommissionBase>(`/commission/${id}/dispatcher`, edit);
    },
    editCommissionStateForOperator: (id: string, edit: CommissionEdit) => {
        return backend.put<CommissionBase>(`/commission/${id}/operator`, edit);
    },
    fetchFinishedCommissionsCSVForDispatcherCompany: (
        dispatcher_co_id: string,
        q?: QueryParams<File, CommissionFilter, CommissionForDispatcherCompany>,
    ) => {
        return backend.get(`/commission/finished/dispatcher-company/${dispatcher_co_id}/csv`, q);
    },
    fetchFinishedCommissionsCSVForAuditorCompany: (
        auditor_co_id: string,
        q?: QueryParams<File, CommissionFilter, CommissionForAuditorCompany>,
    ) => {
        return backend.get(`/commission/finished/auditor-company/${auditor_co_id}/csv`, q);
    },
    groupCommissions: (commission_id: string, other_commission_id: string) => {
        return backend.post<CommissionGroupBase>(
            `/commission/${commission_id}/group-with/${other_commission_id}`,
            undefined,
        );
    },
};
