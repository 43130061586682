import { ReactNode, startTransition } from "react";
import { useNavigate } from "react-router-dom";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.css";
import LanguageMenu from "commons/LanguageMenu";

export default function Header(props: { children?: ReactNode }) {
    const navigate = useNavigate();

    const { t } = useTranslation("common", { keyPrefix: "header" });

    const handleLogout = () => {
        startTransition(() => {
            sessionStorageHelper.clearStorage();
            navigate("/sign-in");
        });
    };

    const loggedInName = sessionStorageHelper.getFullName();

    return (
        <div className={styles.header}>
            <div className={styles.header_content}>
                {props.children}
                <div className={styles.items_wrapper}>
                    <div className={styles.loggedInAs}>{`${t("loggedAs")} ${loggedInName}`}</div>

                    <LanguageMenu type="center" />

                    <button onClick={handleLogout} type="button" className={styles.logout}>
                        {t("logout")}
                    </button>
                </div>
            </div>
        </div>
    );
}
