import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Unathorized() {
    const navigate = useNavigate();
    const { t } = useTranslation("common", { keyPrefix: "unauthorized" });

    useEffect(() => {
        document.title = t("title");
    }, [t]);

    const handleReturn = () => {
        navigate(-1);
    };

    const handleSignIn = () => {
        navigate(`/sign-in`, { replace: true });
    };

    return (
        <div>
            {t("title")}
            <button type="button" onClick={handleReturn}>
                {t("return")}
            </button>
            <button type="button" onClick={handleSignIn}>
                {t("signIn")}
            </button>
        </div>
    );
}
