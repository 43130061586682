import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ContextProvider } from "context/ContextProvider";
import { routesHelper } from "routes/routesHelper";

export default function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <ContextProvider>
                    <Routes>
                        {routesHelper.getRoutes().map((route) => {
                            return <Route key={route.path} path={route.path} element={route.element} />;
                        })}
                    </Routes>
                </ContextProvider>
            </BrowserRouter>
        </div>
    );
}
