import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { faBars, faCircleXmark, faTruck, faTaxi } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import styles from "./Sidenav.module.css";
import cargoLogo from "../../assets/logo_raily_cargo_samoR.svg";
import taxiLogo from "../../assets/logo_raily_taxi_samoR.svg";
import { api } from "api";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
export const cargoURL = process.env.REACT_APP_CARGO_URL;
export const taxiURL = process.env.REACT_APP_TAXI_URL;

interface Item {
    url: string;
    icon: IconDefinition;
    name: string;
    countC?: number;
    countO?: number;

    isExpanded?: boolean;
    target?: boolean;
    img?: string;
}

export const navWidth = "66px";
const navWidthExpanded = "350px";

export default function Sidenav(props: { items: Item[] }) {
    const { t } = useTranslation("common", { keyPrefix: "sidenav" });

    const [isExpanded, setIsExpanded] = useState(false);
    const [countComm, setCountComm] = useState(0);
    const [countOff, setCountOff] = useState(0);
    const [getData, setGetData] = useState(false);

    useEffect(() => {
        if (!getData) {
            setGetData(true);
            getCounts();
            /* setInterval(function () {
                getCounts();
            }, 60000); */
        }
        // eslint-disable-next-line
    }, []);

    const getCounts = () => {
        if (sessionStorageHelper.getDispatcherId() !== "") {
            api.fetchCommissionCountForDispatcherCompany().then((data: any) => { setCountComm(data.data.count) });
        }


        if (sessionStorageHelper.getAuditorManagerId() !== "") {
            api.fetchCommissionCountForAuditorCompany().then((data: any) => { setCountOff(data.data.count) });
        }
    };

    return (
        <div
            className={styles.nav}
            style={{
                width: isExpanded ? navWidthExpanded : navWidth,
                paddingTop: "50px",
                opacity: isExpanded ? "85%" : "100%",
            }}
        >
            <ul>
                <li onClick={() => setIsExpanded(!isExpanded)}>
                    <NavMenuBars
                        text={t("close")}
                        isExpanded={isExpanded}
                        iconClosed={faBars}
                        iconExpanded={faCircleXmark}
                    />
                </li>
                {props.items.map((item) => {
                    return (
                        <li key={item.url}>
                            <NavItem
                                icon={item.icon}
                                url={item.url}
                                isExpanded={isExpanded}
                                name={item.name}
                                countC={countComm}
                                countO={countOff}
                            />
                        </li>
                    );
                })}
            </ul>
            <ul>
                <li>
                    <NavItem
                        isExpanded={isExpanded}
                        icon={faTruck}
                        url={cargoURL ?? "/sign-in"}
                        name={"Raily Cargo"}
                        target={true}
                        img="cargo"
                        countC={countComm}
                    />

                    <ALink
                        isExpanded={isExpanded}
                        icon={faTaxi}
                        url={taxiURL ?? "/sign-in"}
                        name={"Raily Taxi"}
                        target={true}
                        img="taxi"
                        countC={countComm}
                    />
                </li>
            </ul>
        </div>
    );
}

interface ExpandMenuProps {
    isExpanded: boolean;
    iconClosed: IconDefinition;
    iconExpanded: IconDefinition;
    text: string;
}

function NavMenuBars(props: ExpandMenuProps) {
    const { t } = useTranslation("common", { keyPrefix: "sidenav" });

    const [onHover, setOnHover] = useState(false);

    return (
        <div
            className={styles.navitem}
            style={{
                width: props.isExpanded ? navWidthExpanded : navWidth,
                height: navWidth,
            }}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
        >
            {/* icon */}
            <div className={styles.icon_wrapper}>
                <FontAwesomeIcon
                    className={styles.icon}
                    icon={props.isExpanded ? props.iconExpanded : props.iconClosed}
                    bounce={onHover}
                />
            </div>

            {/* label */}
            <p className={styles.p} style={{ opacity: props.isExpanded ? "1" : "0" }}>
                {props.text}
            </p>

            {/* tooltip */}
            {!props.isExpanded && <span className={styles.tooltiptext}>{t("open")}</span>}
        </div>
    );
}

function NavItem(props: Item) {
    const { t } = useTranslation("common");

    const [onHover, setOnHover] = useState(false);

    return (
        <NavLink
            draggable={false}
            className={({ isActive }) => (isActive ? styles.navitem_active : styles.navitem)}
            style={{
                width: props.isExpanded ? navWidthExpanded : navWidth,
                height: navWidth, // square shape
                padding: props.img ? "0 0px 0 0px" : "0 0px 0 18px",
            }}
            to={props.url}
            onMouseEnter={() => setOnHover(true)}
            onMouseLeave={() => setOnHover(false)}
            target={props.target ? "_blank" : ""}
        >
            {/* icon */}
            {props.img && props.img === "cargo" && (
                <img style={{ width: "65px" }} className={styles.logo} src={cargoLogo} alt="Raily Cargo" />
            )}
            {props.img && props.img === "taxi" && (
                <img style={{ width: "65px" }} className={styles.logo} src={taxiLogo} alt="Raily Taxi" />
            )}
            {!props.img && (
                <div className={styles.icon_wrapper}>
                    <FontAwesomeIcon className={styles.icon} icon={props.icon} bounce={onHover} />
                </div>
            )}

            {/* label */}
            <p className={styles.p} style={{ opacity: props.isExpanded ? "1" : "0" }}>
                {t(`${props.name}`)}
            </p>

            {/* tooltip */}
            {!props.isExpanded && <span className={styles.tooltiptext}>{t(`${props.name}`)}</span>}

            {props.name === "routes.dispatcher.commissions" && props.countC !== 0 && (
                <div className={styles.count}>{props.countC}</div>
            )}

            {props.name === "routes.auditorManager.commissions" && props.countO !== 0 && (
                <div className={styles.count}>{props.countO}</div>
            )}
        </NavLink>
    );
}

function ALink(props: Item) {
    const { t } = useTranslation("common");

    return (
        <a
            href={props.url ?? "/sign-in"}
            target={props.target ? "_blank" : ""}
            rel="noreferrer"
            className={styles.navitem}
            style={{
                display: "flex",
                width: props.isExpanded ? navWidthExpanded : navWidth,
                height: navWidth,
                padding: props.img ? "0 0px 0 0px" : "0 0px 0 18px",
            }}
        >
            {props.img && props.img === "taxi" && (
                <img style={{ width: "65px" }} className={styles.logo} src={taxiLogo} alt="Raily Taxi" />
            )}
            {/* label */}
            <p className={styles.p} style={{ opacity: props.isExpanded ? "1" : "0" }}>
                {t(`${props.name}`)}
            </p>
            {/* tooltip */}
            {!props.isExpanded && <span className={styles.tooltiptext}>{t(`${props.name}`)}</span>}
        </a>
    );
}
