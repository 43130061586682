import backend from "api/backend";
import { Credentials } from "api/entities/auth";

/**
 *  ## Object containing functions that fetch API endpoints related to authorization
 *  ### **_authApiService_** functions:
        loginAndGetToken:
            (cred: Credentials) => Promise<backendResponse<AuthResponse, undefined>>
    <br/>
        fetchPublicKey:
            () => Promise<backendResponse<string, undefined>>
 */
export const authApiService = {
    loginAndGetToken: (cred: Credentials) => {
        return backend.login(`/auth/token`, cred);
    },
    fetchPublicKey: () => {
        return backend.get<string>(`/auth/public-key`);
    },
};
