import backend from "api/backend";
import { FileBase } from "api/entities/bases/file";
import FileSaver from "file-saver";

/**
 *  ## Object containing functions that fetch API endpoints related to files
 *  ### **_fileApiService_** functions:
        fetchFiles:
            () => Promise<backendResponse<FileBase[], undefined>>
    <br/>
        fetchFileById:
            (file_id: string [UUID])
                => Promise<backendResponse<FileBase, undefined>>
    <br/>
        registerFile:
            (file: File)
                => Promise<backendResponse<FileBase, undefined>>
    <br/>
        downloadFile:
            (file_id: string [UUID])
                => Promise<backendResponse<any, undefined>>
    <br/>
        removeFile:
            (file_id: string [UUID])
                => Promise<backendResponse<null, undefined>>
 */
export const fileApiService = {
    fetchFiles: () => {
        return backend.get<FileBase[]>(`/files`);
    },
    fetchFileById: (file_id: string) => {
        return backend.get<FileBase>(`/file/${file_id}`);
    },
    registerAuditorCompanyAttachment: (files: File[], id: string) => {
        return backend.postFiles(`/files/auditor-company/${id}`, files);
    },
    registerCommissionAttachment: (files: File[], id: string) => {
        return backend.postFiles(`/files/commission/${id}`, files);
    },
    registerOfferAttachment: (files: File[], id: string) => {
        return backend.postFiles(`/files/offer/${id}`, files);
    },
    downloadFile: (file: FileBase) => {
        backend.get<File>(`/file/download/${file.id}`).then((res) => {
            if (res.ok) FileSaver.saveAs(res.data, file.file_name);
        });
    },
    deleteFile: (file_id: string) => {
        return backend.delete(`/file/${file_id}`);
    },
    fetchAttachmentsForAuditorCo: (auditor_co_id: string) => {
        return backend.get<FileBase[]>(`/files/auditor-company/${auditor_co_id}/attachments`);
    },
    fetchAttachmentsForCommission: (commission_id: string) => {
        return backend.get<FileBase[]>(`/files/commission/${commission_id}/attachments`);
    },
    fetchAttachmentsForOffer: (offer_id: string) => {
        return backend.get<FileBase[]>(`/files/offer/${offer_id}/attachments`);
    },
};
