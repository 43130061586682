import styles from "./SubmitButton.module.css";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
interface localProps {
    success: boolean;
    loading: boolean;
    remove?: boolean;
    label?: string;
}
export default function SubmitButton(props: localProps) {
    const { t } = useTranslation("common", { keyPrefix: "submitButton" });

    return (
        <div className={styles.content}>
            {!props.loading && (
                <button type="submit" className={styles.button} id={props.success ? styles.button_success : ""}>
                    {props.label
                        ? props.label
                        : props.remove
                          ? props.success
                              ? t("removeSuccess")
                              : t("remove")
                          : props.success
                            ? t("success")
                            : t("default")}
                </button>
            )}
            {props.loading && (
                <div className={styles.spinner}>
                    <BeatLoader color="#ffffff" />
                </div>
            )}
        </div>
    );
}
