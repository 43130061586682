import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";

import { ServiceCreate } from "api/entities/bases/service";
import api from "api";
import SingleInput from "commons/Registration/SingleInput";
import { ServiceCategory } from "api/entities/enums";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

export default function Service() {
    const { t } = useTranslation("adminView", { keyPrefix: "register.service" });
    const { handleErrors } = useErrorHandling();
    const [loading, setLoading] = useState(false);

    const [inputs, setInputs] = useState<ServiceCreate>({
        name: "",
        category: ServiceCategory.auditor,
    });

    const clearInputs = () => {
        setInputs({
            name: "",
            category: ServiceCategory.auditor,
        });
    };

    const [button, setButton] = useState({
        success: false,
    });

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        if (button.success) setButton({ success: false });
        const value: string = event.currentTarget.value;
        setInputs({
            ...inputs,
            [event.currentTarget.id]: value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        api.registerService(inputs).then((res) => {
            if (handleErrors(res.error).ok) {
                clearInputs();
                setLoading(false);
                setButton({ success: true });
            } else {
                setLoading(false);
                setButton({ success: false });
            }
        });
    };

    return (
        <form onSubmit={handleSubmit} autoComplete={"off"}>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <SingleInput
                    change={handleChange}
                    text={t("name")}
                    id="name"
                    value={inputs.name}
                    placeholder={t("placeholder")}
                    pattern=".{2,40}"
                    errorMsg={t("namePattern") ?? "error"}
                />

                <SubmitButton loading={loading} success={button.success} />
            </div>
        </form>
    );
}
