interface ContextErrorProps {
    providerName: string;
    consumerName: string;
}

export interface GeneralContextProps {
    isUndefined?: boolean;
}

export const ContextError = ({ providerName, consumerName }: ContextErrorProps) => {
    return (
        <div>
            <h3>Context Error:</h3>
            <p>
                <b>{consumerName}</b> component is positioned outside of the <b>{providerName}</b> component.
            </p>
        </div>
    );
};
