import { useState } from "react";
import styles from "./EditCommission.module.css";
import SingleInput from "commons/Registration/SingleInput";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { Commission, editCommissionService } from "./editCommissionService";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "commons/Registration/DatePicker/CustomDatePicker";
import ListInputAddr from "commons/Registration/ListInput/ListInputAddr";
import DoubleInput from "commons/Registration/DoubleInput";
import Map from "commons/Map";
import { AddressForMap } from "commons/Map/Map";
import ErrorMsg from "commons/ErrorMsg";
import Button from "commons/Button/Button";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

interface LocalProps {
    comm: Commission;
    normalView: () => void;
    cancelEdit: () => void;
}

export default function SecondaryRowEditMode(props: LocalProps) {
    const { t } = useTranslation("dispatcherView", { keyPrefix: "newCommission" });
    const { handleErrors } = useErrorHandling();

    const [commission, setCommission] = useState(props.comm);

    const clearInputs = () => {
        setCommission(props.comm);
    };

    const [loading, setLoading] = useState(false);

    // Stan przycisku submit
    const [button, setButton] = useState({
        success: false,
    });

    const [errMsg, setErrMsg] = useState<{ msg: string; isShown: boolean }>({ msg: "", isShown: false });

    const resetSubmitButton = () => {
        if (button.success) setButton({ success: false });
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        resetSubmitButton();
        setErrMsg({ ...errMsg, isShown: false });
        const value: string = event.currentTarget.value;
        setCommission({
            ...commission,
            [event.currentTarget.id]: value,
        });
    };

    // Used for reseting ListInputAddr component
    const [listBool, setListBool] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (commission.addr_primary_id === "") {
            setErrMsg({ msg: t("noAddress"), isShown: true });
            setLoading(false);
            return;
        }
        if (commission.service_id === "") {
            setErrMsg({ msg: t("noService"), isShown: true });
            setLoading(false);
            return;
        }

        editCommissionService
            .handleSubmit(commission)
            .then((e) => {
                if (handleErrors(e.error).ok) {
                    clearInputs();
                    setButton({ success: true });
                    setListBool(!listBool);
                    props.normalView();
                } else {
                    setButton({ success: false });
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setButton({ success: false });
            });
    };

    const getMarkers = (): AddressForMap[] => {
        var array: AddressForMap[] = [];

        if (commission.addr_primary_latitude !== 0.0 && commission.addr_primary_longitude !== 0.0)
            array.push({
                x: commission.addr_primary_latitude,
                y: commission.addr_primary_longitude,
                name: commission.addr_primary_name,
            });

        if (commission.addr_secondary_latitude !== 0.0 && commission.addr_secondary_longitude !== 0.0)
            array.push({
                x: commission.addr_secondary_latitude,
                y: commission.addr_secondary_longitude,
                name: commission.addr_secondary_name,
            });

        return array;
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className={styles.content}>
                <div className={styles.inputs_wrapper}>
                    <div className={styles.inputs}>
                        <div className={styles.inputs_left}>
                            <SingleInput
                                change={handleChange}
                                id="train_no"
                                text={t("trainNo")}
                                value={commission.train_no}
                                placeholder={t("trainPlaceholder")}
                                pattern={".{1,20}"}
                                errorMsg={t("pattern") ?? "error"}
                            />

                            <SingleInput
                                change={handleChange}
                                id="locomotive"
                                text={t("locomotive")}
                                value={commission.locomotive}
                                placeholder={t("locomotivePlaceholder")}
                                pattern={".{1,20}"}
                                errorMsg={t("pattern") ?? "error"}
                            />

                            <ListInputAddr
                                setInput={(id: string, name: string, lati: number, long: number) => {
                                    resetSubmitButton();
                                    setCommission({
                                        ...commission,
                                        addr_primary_id: id,
                                        addr_primary_name: name,
                                        addr_primary_latitude: lati,
                                        addr_primary_longitude: long,
                                    });
                                }}
                                labelText={t("addressName")}
                                id={"address"}
                                resetElement={listBool}
                                value={commission.addr_primary_name}
                            />

                            <ListInputAddr
                                setInput={(id: string, name: string, lati: number, long: number) => {
                                    resetSubmitButton();
                                    setCommission({
                                        ...commission,
                                        addr_secondary_id: id,
                                        addr_secondary_name: name,
                                        addr_secondary_latitude: lati,
                                        addr_secondary_longitude: long,
                                    });
                                }}
                                labelText={t("addressSecondaryName")}
                                id={"addressSecondary"}
                                resetElement={listBool}
                                required={false}
                                value={commission.addr_secondary_name}
                            />

                            <DoubleInput
                                change={handleChange}
                                text_i1={t("contactPerson")}
                                id_i1={"contact_person"}
                                placeholder_i1={t("contactPerson")}
                                value_i1={commission.contact_person}
                                pattern_i1={".{1,20}"}
                                errorMsg_i1={t("pattern") ?? "error"}
                                text_i2={t("contactPhone")}
                                id_i2={"contact_phone"}
                                placeholder_i2={t("contactPhone")}
                                value_i2={commission.contact_phone}
                                pattern_i2="[+][1-9]([0-9]|([\- ][0-9])){1,14}"
                                errorMsg_i2={`${t("phoneError")} +48 123 123 123`}
                            />

                            <CustomDatePicker
                                value={commission.target_date}
                                onChange={(d: Date) => {
                                    setCommission({ ...commission, target_date: d });
                                }}
                            />
                        </div>

                        <div className={styles.inputs_right}>
                            <Map markers={getMarkers()} title={true} padding={"0 10px 10px 10px"} height={"100%"} />
                        </div>
                    </div>

                    <ErrorMsg msg={errMsg.msg} isShown={errMsg.isShown} />
                    <div style={{ paddingTop: "10px", display: "flex" }}>
                        <SubmitButton
                            loading={loading}
                            success={button.success}
                            label={t("editCommission") ?? "Edytuj"}
                        />
                        <Button id={"delete"} label={t("cancelEdit")} height="40px" click={() => props.cancelEdit()} />
                    </div>
                </div>
            </div>
        </form>
    );
}
