import { ReactNode, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import { ClipLoader } from "react-spinners";

import styles from "./Spinner.module.css";

interface localProps {
    children: ReactNode;
    isFetched: boolean;
    size: string;
    padding?: string;
}

// Odpowiedzialny za wyświetlanie spinnera kiedy dane sa pobierane
export default function Spinner(props: localProps) {
    const ref = useRef(null);
    return (
        <>
            {!props.isFetched && (
                <div style={{ padding: props.padding }} className={styles.spinner}>
                    <ClipLoader color="#3699ff" size={props.size} />
                </div>
            )}

            <CSSTransition
                in={props.isFetched}
                nodeRef={ref}
                timeout={{
                    enter: 300,
                    exit: 0,
                }}
                classNames={{
                    enter: styles.mainEnter,
                    enterActive: styles.mainEnterActive,
                    exit: styles.mainExit,
                    exitActive: styles.mainExitActive,
                }}
                unmountOnExit
                appear
            >
                <>
                    {props.isFetched && (
                        <div ref={ref} style={{ width: "100%" }}>
                            {props.children}
                        </div>
                    )}
                </>
            </CSSTransition>
        </>
    );
}
