import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DoubleInput from "commons/Registration/DoubleInput";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";

import { AdminCreate } from "api/entities/bases/user";
import api from "api";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import SingleInput from "commons/Registration/SingleInput";

export default function Admin() {
    const { t } = useTranslation("adminView", { keyPrefix: "register.admin" });
    const { handleErrors } = useErrorHandling();
    const [loading, setLoading] = useState(false);
    const [rPwdErr, setRPwdErr] = useState(false);

    const [inputs, setInputs] = useState<AdminCreate>({
        name: "",
        surname: "",
        email: "",
        phone_prefix: "",
        phone: "",
        login: "",
        password: "",
        password_repeat: "",
    });

    const clearInputs = () => {
        setInputs({
            name: "",
            surname: "",
            email: "",
            phone_prefix: "",
            phone: "",
            login: "",
            password: "",
            password_repeat: "",
        });
    };

    const [button, setButton] = useState({
        success: false,
    });

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        if (rPwdErr) setRPwdErr(false);
        if (button.success) setButton({ success: false });
        const value: string = event.currentTarget.value;
        setInputs({
            ...inputs,
            [event.currentTarget.id]: value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        if (inputs.password !== inputs.password_repeat) {
            setRPwdErr(true);
            setLoading(false);
            return;
        }

        const adminData: AdminCreate = inputs;

        adminData.phone = adminData.phone.replace(/\s/g, "");

        if (adminData.phone.indexOf("+") === -1) {
            adminData.phone_prefix = "+48";
        } else {
            adminData.phone_prefix = adminData.phone.substring(0, 3);
            adminData.phone = adminData.phone.substring(3);
        }

        api.registerAdmin(adminData).then((res) => {
            if (handleErrors(res.error).ok) {
                clearInputs();
                setLoading(false);
                setButton({ success: true });
            } else {
                setLoading(false);
                setButton({ success: false });
            }
        });
    };

    return (
        <form onSubmit={handleSubmit} autoComplete={"off"}>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <DoubleInput
                    change={handleChange}
                    text_i1={t("name")}
                    id_i1="name"
                    value_i1={inputs.name}
                    placeholder_i1={t("name")}
                    pattern_i1="[\p{L}\.\-\,]{2,20}"
                    errorMsg_i1={t("namePattern") ?? "error"}
                    text_i2={t("surname")}
                    id_i2="surname"
                    value_i2={inputs.surname}
                    placeholder_i2={t("surname")}
                    pattern_i2="[\p{L}\.\-\,]{2,20}"
                    errorMsg_i2={t("namePattern") ?? "error"}
                />

                <DoubleInput
                    change={handleChange}
                    text_i1={t("email")}
                    id_i1="email"
                    value_i1={inputs.email}
                    placeholder_i1={t("email")}
                    pattern_i1="[^@]+@[^@]+\.[^@]+"
                    errorMsg_i1={t("emailError") ?? "error"}
                    text_i2={t("phone")}
                    id_i2="phone"
                    value_i2={inputs.phone}
                    placeholder_i2={t("phone")}
                    pattern_i2="([+][0-9]*)?[1-9][0-9\s]*"
                    errorMsg_i2={`${t("phoneError")} +48 123 123 123`}
                />

                <SingleInput
                    change={handleChange}
                    text={t("login")}
                    id="login"
                    value={inputs.login}
                    placeholder={t("login")}
                    pattern=".{8,32}"
                    errorMsg={t("loginPatter") ?? "error"}
                />

                <DoubleInput
                    change={handleChange}
                    text_i1={t("password")}
                    id_i1="password"
                    value_i1={inputs.password}
                    placeholder_i1={t("password")}
                    password_i1
                    pattern_i1=".{8,32}"
                    errorMsg_i1={t("loginPatter") ?? "error"}
                    text_i2={t("password_repeat")}
                    id_i2="password_repeat"
                    value_i2={inputs.password_repeat}
                    placeholder_i2={t("password_repeat")}
                    password_i2
                    pattern_i2=".{8,32}"
                    errorMsg_i2={t("loginPatter") ?? "error"}
                />

                {rPwdErr && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("repeatPwdErr")}</div>}

                <SubmitButton loading={loading} success={button.success} />
            </div>
        </form>
    );
}
