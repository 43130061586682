import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, TileLayer, useMapEvent } from "react-leaflet";

export default function MapNewAddr(props: {
    newAddr: (lat: number, lon: number) => void;
    lat?: number;
    lon?: number;
    height?: string;
    title?: boolean;
}) {
    const { t } = useTranslation("common", { keyPrefix: "map" });

    const [marker, setMarker] = useState<{ lat: number; lng: number }>({ lat: 0.0, lng: 0.0 });

    function SetNewAddrMarker() {
        useMapEvent("click", (e) => {
            props.newAddr(e.latlng.lat, e.latlng.lng);
            setMarker({ lat: e.latlng.lat, lng: e.latlng.lng });
        });

        return null;
    }

    useEffect(() => {
        if (props.lat && props.lon) {
            setMarker({ lat: props.lat, lng: props.lon });
        }
    }, [props.lat, props.lon]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            {props.title === true && <div> {t("title")} </div>}
            <div style={{ border: "1px solid var(--gray-base)", borderRadius: "4px", width: "100%" }}>
                <MapContainer
                    style={{ width: "100%", height: props.height ?? "550px" }}
                    center={[52.04, 19.28]}
                    zoom={6}
                    scrollWheelZoom={true}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />

                    {marker.lat !== 0.0 && <Marker position={[marker.lat, marker.lng]} />}

                    <SetNewAddrMarker />
                </MapContainer>
            </div>
        </div>
    );
}
