import { State } from "api/entities/enums";
import { OfferForAuditor } from "api/entities/offer";
import format from "date-fns/format";
import { ServiceBase } from "api/entities/bases/service";

const getCommissionState = (c: OfferForAuditor): State => {
    return c.commission.commission_state;
};

const getTargetDate = (c: OfferForAuditor): string => {
    let date: Date = new Date(c.commission.target_date?.toString() as string);
    return format(date, "dd.MM.yyyy - HH:mm");
};

const getID = (c: OfferForAuditor): string => {
    return `${c.commission.identifier}`;
};

const getTrainNo = (c: OfferForAuditor): string => {
    return `${c.commission.train_no}`;
};

const getDispatcherFullName = (c: OfferForAuditor): string => {
    return `${c.commission.dispatcher.user.surname} ${c.commission.dispatcher.user.name}`;
};
const getAuditorManagerFullName = (c: OfferForAuditor): string => {
    return `${c.auditor_manager.user.surname} ${c.auditor_manager.user.name}`;
};

const getCommissionAddressName = (c: OfferForAuditor): string => {
    return `${c.commission.address_primary.name}`;
};

const getCommissionSecondaryAddressName = (c: OfferForAuditor): string => {
    return c.commission.address_secondary ? c.commission.address_secondary.name : "---";
};

const getServices = (c: OfferForAuditor): ServiceBase => {
    return c.commission.service ?? [];
};

const getLocomotive = (c: OfferForAuditor): string => {
    return c.commission.locomotive ?? "---";
};

const commissionsHelper = {
    getCommissionState,
    getTargetDate,
    getDispatcherFullName,
    getCommissionAddressName,
    getCommissionSecondaryAddressName,
    getServices,
    getID,
    getTrainNo,
    getAuditorManagerFullName,
    getLocomotive,
};

export { commissionsHelper };
