import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './CheckField.module.css'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

export default function CheckField(props: { labelText: string, value: boolean, setValue: () => void }) {



    return (
        <div className={styles.wrapper}>
            <div className={styles.input_label} title={props.labelText}>
                {props.labelText}
            </div>
            <div className={styles.checkbox} onClick={() => { props.setValue() }}>
                {props.value ?
                    <FontAwesomeIcon className={styles.icon_selected} icon={faCheck} /> :
                    <FontAwesomeIcon className={styles.icon} icon={faXmark} />
                }
            </div>
        </div>
    );
}