import styles from "./CommissionGroup.module.css";

import { CommissionGroup } from "api/entities/commission";
import { useDataStatusContext } from "commons/DataManagement/DataStatusProvider";
import { useDataListContext } from "commons/DataManagement/DataList";
import { useEffect, useRef, useState } from "react";
import { useDataContext } from "commons/DataManagement/DataProvider";
import { CommissionFilter } from "api/filters/commission";
import { GenericTypeObject } from "api/backend";
import { CommissionBase } from "api/entities/bases/commission";

interface CommissionGroupElementProps<T, G, U extends CommissionBase> {
    gto: GenericTypeObject<T, G, U>;
    e: U;
    refetch: () => void;
    dataStatus: string;
    hasStatuses: boolean;
    active: { [s: string]: boolean };
    statuses: { [s: string]: { color: string; condition: (e: U) => boolean } };
    displayLabels: string[];
    labelGenerator: { [label: string]: { label: (e: U) => string; column?: keyof U } };
    secondary: (gto: GenericTypeObject<T, G, U>, data: U, expand: boolean, refetch: () => void) => React.ReactElement;
}

const CommissionGroupElement = <T, G, U extends CommissionBase>({
    gto,
    e,
    refetch,
    dataStatus,
    hasStatuses,
    active,
    statuses,
    displayLabels,
    labelGenerator,
    secondary,
}: CommissionGroupElementProps<T, G, U>) => {
    const [expand, setExpand] = useState(false);

    return (
        <>
            <tr
                className={styles.tr}
                key={`element_${e.id}_primary`}
                onClick={() => {
                    setExpand(!expand);
                }}
            >
                {hasStatuses && (
                    <td
                        style={{
                            padding: "5px 0 5px 10px",
                        }}
                    >
                        <div
                            className={styles.status_table}
                            style={{
                                backgroundColor: active[dataStatus] ? statuses[dataStatus].color : "white",
                            }}
                        />
                    </td>
                )}
                {displayLabels.map((l) => {
                    return (
                        <td className={styles.td} key={`label_${e.id}_${l}`}>
                            {labelGenerator[l].label(e as U)}
                        </td>
                    );
                })}
            </tr>
            {secondary && secondary(gto, e, expand, refetch)}
        </>
    );
};

interface CommissionGroupComponentProps<T, G, U extends CommissionBase> {
    data: { group: CommissionGroup<U>; ungrouped: boolean };
    t: any;
    children: (gto: GenericTypeObject<T, G, U>, data: U, expand: boolean, refetch: () => void) => React.ReactElement;
}

export const CommissionGroupComponent = <T, G, U extends CommissionBase>({
    data,
    t,
    children,
}: CommissionGroupComponentProps<T, G, U>) => {
    const { refetch } = useDataContext<CommissionGroup<U>, CommissionFilter>();
    const { displayLabels, hasStatuses, labelGenerator } = useDataListContext<U, G>();
    const { active, statuses, statusKeys } = useDataStatusContext<U>();

    const [dataStatuses, setDataStatuses] = useState<{ [k: React.Key]: string }>({});

    const GTO = useRef({} as GenericTypeObject<T, G, U>);

    useEffect(() => {
        if (hasStatuses) {
            let temp = {};
            data.group.data.forEach((e) => {
                for (let s of statusKeys) {
                    if (statuses[s].condition(e)) {
                        temp = { ...temp, [e.id]: s };
                        break;
                    }
                }
            });

            setDataStatuses(temp);
        }
    }, [data, hasStatuses, statusKeys, statuses]);

    return (
        <tbody>
            {
                <tr
                    className={styles.tr_header}
                    key={`element_${data.ungrouped ? data.group.data[0].id : data.group.group_id}_primary`}
                >
                    <td className={styles.td_header} colSpan={9}></td>
                </tr>
            }
            {data.group.data.map((e) => {
                return (
                    <CommissionGroupElement
                        key={`element_${e.id}`}
                        gto={GTO.current}
                        e={e}
                        refetch={refetch}
                        dataStatus={dataStatuses[e.id]}
                        hasStatuses={hasStatuses}
                        active={active}
                        statuses={statuses}
                        displayLabels={displayLabels}
                        labelGenerator={labelGenerator}
                        secondary={children}
                    />
                );
            })}
        </tbody>
    );
};

export default CommissionGroupComponent;
