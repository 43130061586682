import { TitleProvider } from "commons/DataManagement";
import { useTranslation } from "react-i18next";

export default function Home() {
    const { t } = useTranslation("adminView", { keyPrefix: "home" });

    return (
        <div>
            <TitleProvider title={`${t("title")} | Raily Marketplace`} />
        </div>
    );
}
