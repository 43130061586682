import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { CSSTransition } from "react-transition-group";
import localStyles from "./ListInput.module.css";
import commonStyles from "commons/Registration/Registration.module.css";

export default function ListInputDefault(props: {
    data: { name: string }[];
    setInputs: (id: string) => void;
    value: string;
    id: string;
    onElementClear: () => void;
    labelText: string;
    resetElement?: boolean;
    resetFormButton?: () => void;
}) {
    const { t } = useTranslation("common", { keyPrefix: "listInput" });
    const [lockedState, setLockedState] = useState(false);

    const listRef = useRef(null);

    const handleItemClick = (name: string) => {
        if (props.resetFormButton) props.resetFormButton();
        props.setInputs(name);
        setLockedState(true);
    };

    // Resets component
    useEffect(() => {
        setLockedState(false);
        props.setInputs("");
        // eslint-disable-next-line
    }, [props.resetElement]);

    return (
        <div>
            <div className={commonStyles.single_input}>
                <label htmlFor={props.id} className={commonStyles.input_label} title={props.labelText}>
                    {props.labelText}
                </label>
                <div className={localStyles.input_with_button}>
                    {/* Main input */}
                    <input
                        id={props.id}
                        className={commonStyles.input}
                        value={props.value}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {}}
                        type="text"
                        placeholder={t("chooseFromList") ?? ""}
                        autoComplete="off"
                        readOnly
                        title=""
                        spellCheck={false}
                        onInvalid={(e) => e.currentTarget.setCustomValidity(t("onInvalid"))}
                        onInput={(e) => {
                            e.currentTarget.setCustomValidity("");
                        }}
                    ></input>

                    {/* X Button */}
                    <div
                        onClick={() => {
                            setLockedState(false);
                            props.setInputs("");
                            if (props.onElementClear) props.onElementClear();
                        }}
                        className={localStyles.button}
                        id={lockedState === false ? localStyles.button_disabled : ""}
                    >
                        <FontAwesomeIcon
                            className={localStyles.icon}
                            id={lockedState === false ? localStyles.icon_disabled : ""}
                            icon={faXmark}
                        />
                    </div>
                </div>
            </div>
            <CSSTransition
                in={lockedState === false}
                nodeRef={listRef}
                timeout={{
                    enter: 400,
                    exit: 400,
                }}
                classNames={{
                    enter: localStyles.enter,
                    enterActive: localStyles.enterActive,
                    exit: localStyles.exit,
                    exitActive: localStyles.exitActive,
                }}
                mountOnEnter
                unmountOnExit
                appear
            >
                <div style={{ padding: "0 10px 0 10px" }} ref={listRef} className={localStyles.list_wrapper}>
                    <div className={localStyles.list}>
                        <MapData data={props.data} handleClick={handleItemClick} />
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}

interface MapDataProps {
    data: { name: string }[];
    handleClick: (name: string) => void;
}

function MapData(props: MapDataProps) {
    return (
        <>
            {props.data.map((x) => {
                return (
                    <div onClick={() => props.handleClick(x.name)} className={localStyles.list_item} key={x.name}>
                        {x.name}
                    </div>
                );
            })}
        </>
    );
}
