import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DoubleInput from "commons/Registration/DoubleInput";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import api from "api";
import ListInputAddr from "commons/Registration/ListInput/ListInputAddr";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { CompanyCreate } from "api/endpoints/company";
import { useLocation } from "react-router-dom";

export default function Company() {
    const location = useLocation();
    const { t } = useTranslation("adminView", { keyPrefix: "register.auditorCo" });
    const { handleErrors } = useErrorHandling();
    const [loading, setLoading] = useState(false);
    const [insErr, setInsErr] = useState(false);
    const [insNErr, setInsNErr] = useState(false);
    const [edit, setEdit] = useState(() => {
        if (location.state !== null && location.state.edit !== null) {
            return location.state.edit;
        } else {
            return false;
        }
    });
    const [editId, setEditId] = useState(() => {
        if (location.state !== null && location.state.data !== null) {
            return location.state.data.id;
        } else {
            return "";
        }
    });

    // Pola do tworzenia nowej firmy rewidenckiej
    const [inputs, setInputs] = useState<CompanyCreate & { insurance: string; headquarters_addr_id?: string }>(() => {
        if (location.state !== null && location.state.data !== null) {
            return {
                tax_id: location.state.data.tax_id,
                regon: location.state.data.regon,
                krs_no: location.state.data.krs_no,
                company_name: location.state.data.company_name,
                address_id: location.state.data.address_id,
                insurance: location.state.data.insurance_amount ?? "",
                licence_number: location.state.data.licence_number ?? "",
                headquarters_addr_id: location.state.data.address_id,
            };
        } else {
            return {
                tax_id: "",
                regon: "",
                krs_no: "",
                company_name: "",
                address_id: "",
                insurance: "",
                licence_number: "",
                headquarters_addr_id: "",
            };
        }
    });

    // Czyszczenie pól
    const clearInputs = () => {
        setInputs({
            tax_id: "",
            regon: "",
            krs_no: "",
            company_name: "",
            address_id: "",
            insurance: "",
            licence_number: "",
            headquarters_addr_id: "",
        });
    };

    const [button, setButton] = useState({
        success: false,
    });

    const resetSubmitButton = () => {
        if (button.success) setButton({ success: false });
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        resetSubmitButton();
        if (insErr) setInsErr(false);
        if (insNErr) setInsNErr(false);
        const value: string = event.currentTarget.value;
        setInputs({
            ...inputs,
            [event.currentTarget.id]: value,
        });
    };

    const handleEdit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (insErr) setInsErr(false);
        if (insNErr) setInsNErr(false);

        //const compData: CompanyCreate & { insurance: string; headquarters_addr_id?: string; regon: string | undefined } = inputs;

        var compData = {
            tax_id: inputs.tax_id,
            regon: (inputs.regon === "" ? undefined : inputs.regon),
            krs_no: (inputs.krs_no === "" ? undefined : inputs.krs_no),
            company_name: inputs.company_name,
            address_id: inputs.address_id,
            insurance_amount: inputs.insurance_amount,
            licence_number: inputs.licence_number,
            insurance: inputs.insurance,
            headquarters_addr_id: inputs.headquarters_addr_id
        }

        if (compData.insurance.indexOf(',')) compData.insurance = compData.insurance.replace(",", ".");
        compData.insurance_amount = Number.parseFloat(compData.insurance);
        compData.headquarters_addr_id = compData.address_id;

        if (compData.insurance_amount > 1000000000) {
            setLoading(false);
            setInsErr(true);
            return;
        }

        if (compData.insurance_amount < 0) {
            setLoading(false);
            setInsNErr(true);
            return;
        }

        api.editAuditorCompany(editId, compData).then((res) => {
            if (handleErrors(res.error).ok) {
                resetList.current = !resetList.current;
                clearInputs();
                setLoading(false);
                setButton({ success: true });
                setEdit(false);
                setEditId("");
            } else {
                setLoading(false);
                setButton({ success: false });
            }
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);
        if (insErr) setInsErr(false);
        if (insNErr) setInsNErr(false);

        var compData = {
            tax_id: inputs.tax_id,
            regon: (inputs.regon === "" ? undefined : inputs.regon),
            krs_no: (inputs.krs_no === "" ? undefined : inputs.krs_no),
            company_name: inputs.company_name,
            address_id: inputs.address_id,
            insurance_amount: inputs.insurance_amount,
            licence_number: inputs.licence_number,
            insurance: inputs.insurance,
            headquarters_addr_id: inputs.headquarters_addr_id
        }

        if (compData.insurance.indexOf(',')) compData.insurance = compData.insurance.replace(",", ".")
        compData.insurance_amount = Number.parseFloat(compData.insurance);

        if (compData.insurance_amount > 1000000000) {
            setLoading(false);
            setInsErr(true);
            return;
        }

        if (compData.insurance_amount < 0) {
            setLoading(false);
            setInsNErr(true);
            return;
        }

        api.registerCompany(compData).then((res) => {
            if (handleErrors(res.error).ok) {
                resetList.current = !resetList.current;
                clearInputs();
                setLoading(false);
                setButton({ success: true });
            } else {
                setLoading(false);
                setButton({ success: false });
            }
        });
    };

    var resetList = useRef(false);

    return (
        <form onSubmit={edit ? handleEdit : handleSubmit} autoComplete={"off"}>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <DoubleInput
                    change={handleChange}
                    text_i1={t("coName")}
                    id_i1="company_name"
                    value_i1={inputs.company_name}
                    pattern_i1="^(?!\s*$).{1,255}"
                    errorMsg_i1={t('companyError') ?? 'error'}
                    placeholder_i1={t("coName")}
                    text_i2={t("taxNo")}
                    id_i2="tax_id"
                    value_i2={inputs.tax_id}
                    placeholder_i2={t("taxNo")}
                    pattern_i2="[0-9]{10}"
                    errorMsg_i2={t("taxNoError") ?? ""}
                />

                <DoubleInput
                    change={handleChange}
                    text_i1={t("regon")}
                    id_i1="regon"
                    value_i1={inputs.regon}
                    placeholder_i1={t("regon")}
                    pattern_i1="[0-9]{0,14}"
                    errorMsg_i1={t("regonError") ?? ""}
                    optional_i1={true}
                    text_i2={t("krs")}
                    id_i2="krs_no"
                    value_i2={inputs.krs_no}
                    placeholder_i2={t("krs")}
                    pattern_i2="[0-9]{0,10}"
                    errorMsg_i2={t("krsError") ?? ""}
                    optional_i2={true}
                />

                <DoubleInput
                    change={handleChange}
                    text_i1={t("insurance")}
                    id_i1="insurance"
                    value_i1={inputs.insurance}
                    pattern_i1="[\-]?[0-9]*[\,\.]?[0-9]{0,1}[0-9]{0,1}"
                    errorMsg_i1={t("numberErr") ?? "error"}
                    placeholder_i1={t("insurance")}
                    text_i2={t("license")}
                    id_i2="licence_number"
                    value_i2={inputs.licence_number}
                    pattern_i2=".{1,50}"
                    placeholder_i2={t("license")}
                    errorMsg_i2={t('ins') ?? 'error'}
                />

                <ListInputAddr
                    setInput={(id: string) => {
                        setInputs({ ...inputs, address_id: id });
                    }}
                    labelText={t("address")}
                    id="addr_name"
                    resetElement={resetList.current}
                    value={location.state !== null ? location.state.data.address.name : undefined}
                />

                {insErr && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("insErr")}</div>}
                {insNErr && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("insNegErr")}</div>}

                {edit ? (
                    <SubmitButton loading={loading} success={button.success} label={t("edit") ?? ""} />
                ) : (
                    <SubmitButton loading={loading} success={button.success} />
                )}
            </div>
        </form>
    );
}
