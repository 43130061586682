import { UserProfile } from "api/entities/user";
import jwt_decode from "jwt-decode";

/*

    Even though file is called 'sessionStorageHelper'
    its using a localStorage to keep data in the browser

*/

export type Aspect = "admin" | "dispatcher" | "operator" | "worker" | "none";

const setStorageOnLogin = (token: string): void => {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(jwt_decode(token)));
};

const getToken = (): string => {
    return localStorage.getItem("token") ?? "";
};

const getUserProfile = (): UserProfile | undefined => {
    const user_json = JSON.parse(localStorage.getItem("user") ?? "{}");

    if (user_json.id !== undefined) return user_json as UserProfile;

    return undefined;
};

const getAspects = (user?: UserProfile): Aspect[] => {
    if (user === undefined) user = getUserProfile();

    let aspects: Aspect[] = [];

    if (user === undefined) return aspects;

    if (user.admin !== undefined && user.admin !== null) aspects.push("admin");
    if (user.dispatcher !== undefined && user.dispatcher !== null) aspects.push("dispatcher");
    if (user.auditor_manager !== undefined && user.auditor_manager !== null) aspects.push("operator");
    if (user.auditor !== undefined && user.auditor !== null) aspects.push("worker");

    if (aspects.length === 0) aspects.push("none");

    return aspects;
};

const getFullName = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "Error";

    return user.name + " " + user.surname;
};

const getEmail = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "Error";

    return user.email;
};

const getPhone = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "Error";

    return user.phone;
};

const getAuditorCoId = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "";

    return user.company_id ?? "error";
};

const getDispatcherCoId = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "";

    return user.company_id ?? "error";
};

const getUserId = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "Error";

    return user.id;
};

const getAuditorManagerId = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "";

    if (user.auditor_manager !== null) return user.auditor_manager.id ?? "error";

    return "";
};

const getAuditorId = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "";

    if (user.auditor) return user.auditor.id ?? "error";

    return "";
};

const getDispatcherId = (): string => {
    const user = getUserProfile();

    if (user === undefined) return "";

    if (user.dispatcher) return user.dispatcher.id ?? "error";

    return "";
};

const clearStorage = (): void => {
    localStorage.clear();
};

const sessionStorageHelper = {
    setStorageOnLogin,
    getToken,
    getAspects,

    getFullName,
    getEmail,
    getPhone,

    getUserId,
    getAuditorManagerId,
    getAuditorId,
    getDispatcherId,

    getAuditorCoId,
    getDispatcherCoId,

    clearStorage,
};

export { sessionStorageHelper };
