import { ReactNode, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./CustomCssTransition.module.css";

interface localProps {
    in: boolean; // triggers the enter / exit states
    timeout?: number; // in
    children: ReactNode;
    mountOnEnter?: boolean;
    unmountOnEnter?: boolean;
    id?: React.Key;

    bgColor?: string;

    trMode?: boolean;
    colSpan?: number;
}

export default function CustomCssTransition(props: localProps) {
    const transitionRef = useRef(null);

    return (
        <CSSTransition
            in={props.in}
            nodeRef={transitionRef}
            timeout={props.timeout ?? 300}
            classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                exit: styles.exit,
                exitActive: styles.exitActive,
            }}
            mountOnEnter={props.mountOnEnter ?? true}
            unmountOnExit={props.unmountOnEnter ?? true}
            key={props.id}
        >
            {props.trMode ? (
                <tr ref={transitionRef} key={`${props.id}_body`}>
                    <td colSpan={props.colSpan ?? 1}>{props.children}</td>
                </tr>
            ) : (
                <div ref={transitionRef} key={`${props.id}_body`}>
                    {props.children}
                </div>
            )}
        </CSSTransition>
    );
}
