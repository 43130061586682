import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DoubleInput from "commons/Registration/DoubleInput";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import api from "api";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { UserCreateRequest } from "api/entities/bases/user";
import SingleInput from "commons/Registration/SingleInput";
import DataList from "commons_new/DataList/DataList";
import CheckField from "commons/Registration/CheckField/CheckField";

export default function AuditorMngr() {
    const { t } = useTranslation("adminView", { keyPrefix: "register.manager" });
    const { handleErrors } = useErrorHandling();
    const [loading, setLoading] = useState(false);
    const [rPwdErr, setRPwdErr] = useState(false);
    const [compErr, setCompErr] = useState(false);
    const [key, setKey] = useState(Math.random());
    const [dualRole, setDualRole] = useState(false);

    // Pola do tworzenia nowego kierownika rewidentów
    const [inputs, setInputs] = useState<UserCreateRequest>({
        name: "",
        surname: "",
        email: "",
        phone_prefix: "",
        phone: "",
        login: "",
        password: "",
        auditor_manager: {
            auditor_co_id: "",
        },
        company_id: "",
        password_repeat: "",
    });

    // Czyszczenie pól
    const clearInputs = () => {
        setInputs({
            name: "",
            surname: "",
            email: "",
            phone_prefix: "",
            phone: "",
            login: "",
            password: "",
            auditor_manager: {
                auditor_co_id: "",
            },
            company_id: "",
            password_repeat: "",
        });
    };

    const [button, setButton] = useState({
        success: false,
    });

    const resetSubmitButton = () => {
        if (button.success) setButton({ success: false });
    };

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        resetSubmitButton();
        if (rPwdErr) setRPwdErr(false);
        if (compErr) setCompErr(false);
        const value: string = event.currentTarget.value;
        setInputs({
            ...inputs,
            [event.currentTarget.id]: value,
        });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setLoading(true);

        if (inputs.password !== inputs.password_repeat) {
            setRPwdErr(true);
            setLoading(false);
            return;
        }

        if (inputs.company_id === "") {
            setCompErr(true);
            setLoading(false);
            return;
        }

        const data: UserCreateRequest = inputs;

        data.phone = data.phone.replace(/\s/g, "");

        if (data.phone.indexOf("+") === -1) {
            data.phone_prefix = "+48";
        } else {
            data.phone_prefix = data.phone.substring(0, 3);
            data.phone = data.phone.substring(3);
        }

        const req = {
            name: inputs.name,
            surname: inputs.surname,
            email: inputs.email,
            phone_prefix: data.phone_prefix,
            phone: data.phone,
            login: inputs.login,
            password: inputs.password,
            company_id: inputs.company_id ?? "error",
        };

        if (dualRole) {
            api.registerDispatcherAndOperator(req).then((res) => {
                if (handleErrors(res.error).ok) {
                    clearInputs();
                    setButton({ success: true });
                    setKey(Math.random());
                    setCompErr(false);
                    setRPwdErr(false);
                } else {
                    setButton({ success: false });
                }
                setLoading(false);
                setListBool(!listBool);
            });
        }
        else {
            api.registerAuditorManager(req).then((res) => {
                if (handleErrors(res.error).ok) {
                    clearInputs();
                    setButton({ success: true });
                    setKey(Math.random());
                    setCompErr(false);
                    setRPwdErr(false);
                } else {
                    setButton({ success: false });
                }
                setLoading(false);
                setListBool(!listBool);
            });
        }

    };

    //for refreshing listinputaddr
    const [listBool, setListBool] = useState(false);

    return (
        <form onSubmit={handleSubmit} autoComplete={"off"}>
            <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <DoubleInput
                    change={handleChange}
                    text_i1={t("name")}
                    id_i1="name"
                    value_i1={inputs.name}
                    placeholder_i1={t("name")}
                    pattern_i1=".{2,20}"
                    errorMsg_i1={t("namePattern") ?? "error"}
                    text_i2={t("surname")}
                    id_i2="surname"
                    value_i2={inputs.surname}
                    placeholder_i2={t("surname")}
                    pattern_i2=".{2,20}"
                    errorMsg_i2={t("namePattern") ?? "error"}
                />

                <DoubleInput
                    change={handleChange}
                    text_i1={t("email")}
                    id_i1="email"
                    value_i1={inputs.email}
                    placeholder_i1={t("email")}
                    pattern_i1="[^@]+@[^@]+\.[^@]+"
                    errorMsg_i1={t("emailErr") ?? "error"}
                    text_i2={t("phone")}
                    id_i2="phone"
                    value_i2={inputs.phone}
                    placeholder_i2={t("phone")}
                    pattern_i2="([+][0-9]*)?[1-9][0-9\s]*"
                    errorMsg_i2={`${t("phoneError")} +48 123 123 123`}
                />

                <SingleInput
                    change={handleChange}
                    text={t("login")}
                    id="login"
                    value={inputs.login}
                    placeholder={t("login")}
                    pattern=".{8,32}"
                    errorMsg={t("loginPatter") ?? "error"}
                />

                <DoubleInput
                    change={handleChange}
                    text_i1={t("password")}
                    id_i1="password"
                    value_i1={inputs.password}
                    placeholder_i1={t("password")}
                    password_i1
                    pattern_i1=".{8,32}"
                    errorMsg_i1={t("loginPatter") ?? "error"}
                    text_i2={t("password_repeat")}
                    id_i2="password_repeat"
                    value_i2={inputs.password_repeat}
                    placeholder_i2={t("password_repeat")}
                    password_i2
                    pattern_i2=".{8,32}"
                    errorMsg_i2={t("loginPatter") ?? "error"}
                />

                <DataList
                    key={key}
                    type="company"
                    setInputs={(id: string) => {
                        setInputs({ ...inputs, company_id: id });
                        resetSubmitButton();
                    }}
                    id="auditor_co_name"
                    labelText={t("auditorCo")}
                />

                <CheckField labelText={t('doubleRole')} value={dualRole} setValue={() => { setDualRole(!dualRole) }} />

                {rPwdErr && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("repeatPwdErr")}</div>}

                {compErr && <div style={{ color: "var(--red-base)", textAlign: "center" }}>{t("compErr")}</div>}

                <SubmitButton loading={loading} success={button.success} />
            </div>
        </form>
    );
}
