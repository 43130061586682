import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "styles/UserListing.module.css";
import api from "api";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { ContractWithDispatcherCompany } from "api/entities/contract";
import CustomCssTransition from "commons/CustomCssTransition";
import SecondaryRow from "./SecondaryRow";
import { DataList, DataProvider, TitleProvider } from "commons/DataManagement";
import { ContractDispatcherCoFilter } from "api/filters/contract";

export const Contracts = () => {
    const { t } = useTranslation("auditorManagerView", { keyPrefix: "contracts" });

    const filterRef = useRef({
        dispatcher_co_name: "",
    } as ContractDispatcherCoFilter);

    return (
        <div className={styles.content}>
            <TitleProvider title={`${t("title")} | Raily Marketplace`} />
            <DataProvider
                fetchFun={api.fetchContractsByAuditorCoWithPricesDispatcherCompany}
                fetchArgs={[sessionStorageHelper.getAuditorCoId()]}
                query={{
                    order_by: "dispatcher_company_name" as keyof ContractWithDispatcherCompany,
                    descending: true,
                }}
            >
                {(gto) => (
                    <>
                        <div className={styles.content_row}>
                            <h1 className={styles.h1}>{t("title")}</h1>
                        </div>
                        <div className={styles.content_row}>
                            <div className={styles.row_items}>
                                <DataProvider.RefreshButton gto={gto} label={t("refresh")} />
                            </div>
                        </div>
                        <div className={styles.table_wrapper}>
                            <DataList
                                gto={gto}
                                keyGenerator={(e) => e.id}
                                labelGenerator={{
                                    coName: {
                                        label: (e) => e.dispatcher_company.company_name,
                                        column: "dispatcher_company_name",
                                        filter: {
                                            key: "dispatcher_co_name",
                                            type: "string",
                                        },
                                    },
                                    taxNo: {
                                        label: (e) => e.dispatcher_company.tax_id,
                                        column: "dispatcher_company_nip",
                                        filter: {
                                            key: "dispatcher_co_nip",
                                            type: "string",
                                        },
                                    },
                                    regon: {
                                        label: (e) => e.dispatcher_company.regon,
                                    },
                                    krs: {
                                        label: (e) => e.dispatcher_company.krs_no,
                                    },
                                }}
                                defaultOrder={"dispatcher_company_name"}
                                descendingDefaultOrder
                                tableWrapperElement={<table className={styles.table} />}
                                headerElement={
                                    <DataList.Header
                                        gto={gto}
                                        translation={t}
                                        headerElement={<thead className={styles.thead} />}
                                        labelElement={<th scope="col" className={styles.th} />}
                                        filterElement={<input className={styles.input} />}
                                        filterRef={filterRef}
                                    />
                                }
                                dataRowElement={<tr className={styles.tr} />}
                                dataLabelElement={<td className={styles.td} />}
                                statusMarkerElement={<div className={styles.status_table} />}
                            >
                                {(d, e) => (
                                    <CustomCssTransition in={e} trMode={true} colSpan={6}>
                                        <SecondaryRow data={d} expand={e} />
                                    </CustomCssTransition>
                                )}
                            </DataList>
                        </div>
                        <div className={styles.row_items}>
                            <DataProvider.Pagination gto={gto} />
                        </div>
                    </>
                )}
            </DataProvider>
        </div>
    );
};

export default Contracts;
