import { faFile, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "api";
import { ChangeEvent, DragEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Attachments.module.css";
import { DataProvider } from "commons/DataManagement";
import { FileBase } from "api/entities/bases/file";
import { useConfirm } from "commons/hooks/useConfirm";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

const allowedTypes = ["application/pdf", "text/plain", "image/jpeg", "image/jpg", "image/png"];

export default function Attachments(props: {
    addFile: (f: File) => void;
    mode: "o" | "c" | "a";
    id: string;
    ableToAdd: boolean;
}) {
    const { t } = useTranslation("common", { keyPrefix: "attachments" });
    const { handleErrors } = useErrorHandling();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const confirmData = useRef<{ file: FileBase | null; refetch: () => void }>({ file: null, refetch: () => {} });
    const handleDelete = () => {
        deleteFile(confirmData.current.file ?? { id: "", file_name: "" }, confirmData.current.refetch);
    };
    const handleAbort = () => {
        /* ... */
    };
    const confirmDelete = useConfirm(t("deleteChoice"), handleDelete, handleAbort);

    const [dragActive, setDragActive] = useState(false);
    const prevState = useRef("dragenter");
    const [typeErr, setTypeErr] = useState(false);
    const [sizeErr, setSizeErr] = useState(false);

    const downloadFile = (file: FileBase) => {
        api.downloadFile(file);
    };

    const deleteFile = (file: FileBase, refetch: () => void) => {
        api.deleteFile(file.id).then((e) => {
            handleErrors(e.error);
            setTimeout(() => refetch(), 10);
        });
    };

    const handleFiles = (files: File[], refetch: () => void) => {
        if (files.length > 0) {
            let resp;

            if (props.mode === "c") resp = api.registerCommissionAttachment(files, props.id);
            else if (props.mode === "o") resp = api.registerOfferAttachment(files, props.id);
            else if (props.mode === "a") resp = api.registerAuditorCompanyAttachment(files, props.id);

            if (resp !== undefined)
                resp.then((e) => {
                    if (e.status === 413) {
                        setSizeErr(true);
                    }

                    if (handleErrors(e.error).ok) setTimeout(() => refetch(), 10);
                });
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, refetch: () => void) => {
        let files: File[] = [];
        if (typeErr) setTypeErr(false);
        if (sizeErr) setSizeErr(false);
        let size = 0;

        if (event.currentTarget.files && event.currentTarget.files[0]) {
            for (let i = 0; i < event.currentTarget.files.length; i++) {
                let f = event.currentTarget.files.item(i);

                if (f !== null) {
                    if (allowedTypes.indexOf(f?.type ?? "") > -1) {
                        files.push(f);
                        props.addFile(f);
                        size = size + f.size;
                    } else {
                        setTypeErr(true);
                    }
                }
            }
        }

        if (size > 26214400) {
            setSizeErr(true);
            files = [];
            return;
        }

        handleFiles(files, refetch);
        event.currentTarget.value = "";
    };

    const handleFileDrop = (event: DragEvent<HTMLDivElement>, refetch: () => void) => {
        event.preventDefault();
        event.stopPropagation();

        const files: File[] = [];

        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            for (let i = 0; i < event.dataTransfer.files.length; i++) {
                let f = event.dataTransfer.files.item(i);
                if (f !== null) {
                    if (allowedTypes.indexOf(f?.type ?? "") > -1) {
                        files.push(f);
                        props.addFile(f);
                    } else {
                        setTypeErr(true);
                    }
                }
            }
        }

        handleFiles(files, refetch);
        setDragActive(false);
    };

    const handleDrag = (event: DragEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!dragActive && (event.type === "dragenter" || event.type === "dragover")) setDragActive(true);
        if (dragActive && event.type === "dragleave" && prevState.current !== "dragenter") setDragActive(false);

        prevState.current = event.type;
    };

    const handleNewAttachmentClick = () => {
        if (inputRef.current) inputRef.current.click();
    };

    return (
        <div className={styles.content_wrapper}>
            <DataProvider
                fetchFun={
                    props.mode === "c"
                        ? api.fetchAttachmentsForCommission
                        : props.mode === "o"
                          ? api.fetchAttachmentsForOffer
                          : api.fetchAttachmentsForAuditorCo
                }
                fetchArgs={[props.id]}
            >
                {(gto, data, fetched, refetch) => (
                    <div className={styles.content}>
                        <div className={styles.header}>
                            {props.mode === "c"
                                ? t("attachmentsCommission")
                                : props.mode === "o"
                                  ? t("attachmentsOffer")
                                  : t("attachmentsAuditorCo")}
                        </div>
                        <div className={styles.main}>
                            {/* Attachment listing, ability to download selected file */}
                            {(data === undefined || data === null || data.length === 0) && (
                                <div className={styles.attachment}>{t("noAttachments")}</div>
                            )}

                            {data !== undefined &&
                                data !== null &&
                                data.map((e) => {
                                    return (
                                        <div className={styles.attachment} key={e.id}>
                                            <div className={styles.attachment_download} onClick={() => downloadFile(e)}>
                                                <FontAwesomeIcon className={styles.icon} icon={faFile} />
                                                <div className={styles.file_name}>{e.file_name}</div>
                                            </div>
                                            {props.ableToAdd === true && (
                                                <>
                                                    <div
                                                        className={styles.attachment_delete}
                                                        onClick={() => {
                                                            confirmData.current = { file: e, refetch: refetch };
                                                            confirmDelete();
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            className={styles.icon_delete}
                                                            icon={faTrash}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    );
                                })}

                            {/* New attachment button */}
                            {props.ableToAdd === true && (
                                <div
                                    className={`${styles.attachment} ${dragActive ? styles.attachment_drag : ""}`}
                                    onDrop={(e) => handleFileDrop(e, refetch)}
                                    onDragEnter={handleDrag}
                                    onDragOver={handleDrag}
                                    onDragLeave={handleDrag}
                                >
                                    <input
                                        type={"file"}
                                        name={"file"}
                                        ref={inputRef}
                                        onChange={(e) => handleFileChange(e, refetch)}
                                        style={{ display: "none" }}
                                        multiple
                                    />
                                    <div
                                        className={
                                            props.ableToAdd === true
                                                ? styles.attachment_download_add
                                                : styles.attachment_download
                                        }
                                        onClick={handleNewAttachmentClick}
                                    >
                                        <FontAwesomeIcon className={styles.icon_new} icon={faPlus} />
                                        <FontAwesomeIcon
                                            className={styles.icon_new}
                                            icon={faFile}
                                            style={{ height: "50px" }}
                                        />
                                    </div>
                                </div>
                            )}

                            {typeErr && <div className={styles.attachment_row}>{t("typeErr")}</div>}
                            {sizeErr && <div className={styles.attachment_row}>{t("sizeErr")}</div>}
                        </div>
                    </div>
                )}
            </DataProvider>
        </div>
    );
}
