export enum ComparisonMode {
    EQ = "eq",
    NEQ = "neq",
    IN = "in",
    NOTIN = "notin",
}

export enum StringMatchingMode {
    START = "start",
    END = "end",
    INCLUDE = "include",
    EXCLUDE = "exclude",
    EXACT = "exact",
}

export enum NumberComparisonMode {
    EQ = "eq",
    NEQ = "neq",
    GT = "gt",
    LT = "lt",
    GE = "ge",
    LE = "le",
}

export const parseNumberComparisonMode = (s?: string) => {
    if (s === undefined) return undefined;

    const gt = s.includes(">");
    const lt = s.includes("<");
    const eq = s.includes("=");
    const nt = s.includes("!");

    const ltend = s.endsWith("<");

    if (gt) {
        if (ltend)
            if (eq) return NumberComparisonMode.LE;
            else return NumberComparisonMode.LT;
        else if (eq) return NumberComparisonMode.GE;
        else return NumberComparisonMode.GT;
    } else if (lt) {
        if (eq) return NumberComparisonMode.LE;
        else return NumberComparisonMode.LT;
    } else if (nt) {
        return NumberComparisonMode.NEQ;
    }

    return undefined;
};

export const numberComparisonModeToString = (mode?: NumberComparisonMode) => {
    switch (mode) {
        case NumberComparisonMode.GE:
            return ">=";
        case NumberComparisonMode.GT:
            return ">";
        case NumberComparisonMode.LE:
            return "<=";
        case NumberComparisonMode.LT:
            return "<";
        case NumberComparisonMode.EQ:
            return "=";
        case NumberComparisonMode.NEQ:
            return "!=";
        default:
            return "";
    }
};

export enum DatetimeComparisonMode {
    AFTER = "after",
    BEFORE = "before",
    EXACT = "exact",
}
