import ReactDOM from "react-dom/client";
import App from "commons/App";
import "./i18n";
import "index.css";
import reportWebVitals from "reportWebVitals";

let root = undefined;
export let IS_DOC = false;

try {
    root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
} catch (e) {
    root = ReactDOM.createRoot(document.getElementById("rsg-root") as HTMLElement);
    IS_DOC = true;
}

export enum Mode {
    Auditor = "Rewident",
    Dispatcher = "Dyspozytor",
    AuditorComp = "Firma rewidencka",
    DispatcherComp = "Firma kolejowa",
    Admin = "Admin",
    Address = "Adres",
    AuditorMngr = "Kierownik rewidentów",
    Service = "Usługa",
    Pricing = "Cennik", //?
    Contract = "Kontrakt",
    Commission = "Zlecenie",
    Offer = "Oferta",
}

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
