import backend, { QueryParams } from "api/backend";
import {
    AuditorManagerWithAuditorCompany,
    AuditorManagerWithAuditorCompanyUser,
    AuditorManagerWithUser,
} from "api/entities/auditorManager";
import { AuditorManagerBase, AuditorManagerEdit } from "api/entities/bases/auditorManager";
import { UserCreateRequest } from "api/entities/bases/user";
import { AuditorManagerFilter, AuditorManagerWithCompanyFilter } from "api/filters/auditor_manager";

export const auditorManagerApiService = {
    fetchAuditorManagers: (q?: QueryParams<AuditorManagerBase[], AuditorManagerWithCompanyFilter>) => {
        return backend.get(`/auditor-managers`, q);
    },
    fetchAuditorManager: (id: string) => {
        return backend.get<AuditorManagerBase>(`/auditor-manager/${id}`);
    },
    fetchAuditorManagersWithUser: (q?: QueryParams<AuditorManagerWithUser[], AuditorManagerWithCompanyFilter>) => {
        return backend.get(`/auditor-managers/user`, q);
    },
    fetchAuditorManagerWithUser: (id: string) => {
        return backend.get<AuditorManagerWithUser>(`/auditor-manager/user/${id}`);
    },
    fetchAuditorManagersWithAuditorCompany: (
        q?: QueryParams<AuditorManagerWithAuditorCompany[], AuditorManagerWithCompanyFilter>,
    ) => {
        return backend.get(`/auditor-managers/auditor-company`, q);
    },
    fetchAuditorManagerWithAuditorCompany: (id: string) => {
        return backend.get<AuditorManagerWithAuditorCompany>(`/auditor-manager/auditor-company/${id}`);
    },
    fetchAuditorManagersWithAuditorCompanyAndUser: (
        q?: QueryParams<AuditorManagerWithAuditorCompanyUser[], AuditorManagerWithCompanyFilter>,
    ) => {
        return backend.get(`/auditor-managers/user-and-auditor-company`, q);
    },
    fetchAuditorManagerWithAuditorCompanyAndUser: (id: string) => {
        return backend.get<AuditorManagerWithAuditorCompanyUser>(`/auditor-manager/user-and-auditor-company/${id}`);
    },
    fetchAuditorManagersByAuditorCompany: (
        auditor_co_id: string,
        q?: QueryParams<AuditorManagerWithUser[], AuditorManagerFilter>,
    ) => {
        return backend.get(`/auditor-manager/user/auditor-company/${auditor_co_id}`, q);
    },
    registerAuditorManager: (manager: UserCreateRequest) => {
        return backend.post<AuditorManagerBase>(`/auditor-manager`, manager);
    },
    removeAuditorManager: (id: string) => {
        return backend.delete(`/auditor-manager/${id}`);
    },
    editAuditorManager: (id: string, edit: AuditorManagerEdit) => {
        return backend.put<AuditorManagerBase>(`/auditor-manager/${id}`, edit);
    },
};
