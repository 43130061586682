import backend, { QueryParams } from "api/backend";
import { DispatcherCoWithAddress } from "api/entities/dispatcherCo";
import { CompanyCreate, CompanyEdit } from "api/entities/bases/companyCommon";
import { DispatcherCoBase } from "api/entities/bases/dispatcherCo";
import { DispatcherCoFull, DispatcherCoWithContracts } from "api/entities/dispatcherCo";
import { DispatcherCoAddressFilter, DispatcherCoFilter } from "api/filters/dispatcher_co";

/**
 *  ## Object containing functions that fetch API endpoints related to cargo companies
 *  ### **_dispatcherCoApiService_** functions:
        fetchDispatcherCompanies:
            (q?: QueryParams<DispatcherCoBase[], DispatcherCoFilter>)
                => Promise<backendResponse<DispatcherCoBase[], DispatcherCoFilter>>
    <br/>
        fetchDispatcherCompany:
            (id: string [UUID])
                => Promise<backendResponse<DispatcherCoFull, undefined>>
    <br/>
        fetchDispatcherCompaniesWithAddress:
            (q?: QueryParams<DispatcherCoWithAddress[], DispatcherCoAddressFilter>)
                => Promise<backendResponse<DispatcherCoWithAddress[], DispatcherCoAddressFilter>>
    <br/>
        fetchDispatcherCompanyWithAddress:
            (id: string [UUID])
                => Promise<backendResponse<DispatcherCoWithAddress, undefined>>
    <br/>
        fetchDispatcherCompaniesWithContracts:
            (q?: QueryParams<DispatcherCoWithContracts[], DispatcherCoFilter>)
                => Promise<backendResponse<DispatcherCoWithContracts[], DispatcherCoFilter>>
    <br/>
        registerDispatcherCompany:
            (comp: CompanyCreate)
                => Promise<backendResponse<DispatcherCoBase, undefined>>
    <br/>
        removeDispatcherCompany:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editDispatcherCompany:
            (id: string [UUID], edit: CompanyEdit)
                => Promise<backendResponse<DispatcherCoBase, undefined>>
 */
export const dispatcherCoApiService = {
    fetchDispatcherCompanies: (q?: QueryParams<DispatcherCoBase[], DispatcherCoFilter>) => {
        return backend.get(`/dispatcher-companies`, q);
    },
    fetchDispatcherCompany: (id: string) => {
        return backend.get<DispatcherCoFull>(`/dispatcher-company/${id}`);
    },
    fetchDispatcherCompaniesWithAddress: (q?: QueryParams<DispatcherCoWithAddress[], DispatcherCoAddressFilter>) => {
        return backend.get(`/dispatcher-companies/address`, q);
    },
    fetchDispatcherCompanyWithAddress: (id: string) => {
        return backend.get<DispatcherCoWithAddress>(`/dispatcher-company/address/${id}`);
    },
    fetchDispatcherCompaniesWithContracts: (q?: QueryParams<DispatcherCoWithContracts[], DispatcherCoFilter>) => {
        return backend.get(`/dispatcher-companies/contracts`, q);
    },
    registerDispatcherCompany: (comp: CompanyCreate) => {
        return backend.post<DispatcherCoBase>(`/dispatcher-company`, comp);
    },
    removeDispatcherCompany: (id: string) => {
        return backend.delete(`/dispatcher-company/${id}`);
    },
    editDispatcherCompany: (id: string, edit: CompanyEdit) => {
        return backend.put<DispatcherCoBase>(`/dispatcher-company/${id}`, edit);
    },
};
