import { useEffect } from "react";

interface TitleProviderProps {
    title: string;
}

export const TitleProvider = ({ title }: TitleProviderProps) => {
    useEffect(() => {
        document.title = title;
    }, [title]);

    return <></>;
};
