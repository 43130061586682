import { CSSProperties, HTMLInputTypeAttribute, useEffect, useRef, useState } from "react";
import styles from "./Registration.module.css";
import { useTranslation } from "react-i18next";
import { useLoginDebounce } from "api";

interface localProps {
    change: (event: React.FormEvent<HTMLInputElement>) => void;
    change_i2?: (event: React.FormEvent<HTMLInputElement>) => void;

    text_i1: string;
    id_i1: string;
    placeholder_i1?: any;
    value_i1: any;
    password_i1?: boolean;
    type_i1?: HTMLInputTypeAttribute;
    pattern_i1?: string;
    title_i1?: string;
    errorMsg_i1?: string;
    login_i1?: boolean;
    optional_i1?: boolean;

    text_i2: string;
    id_i2: string;
    placeholder_i2?: any;
    value_i2: any;
    password_i2?: boolean;
    type_i2?: HTMLInputTypeAttribute;
    pattern_i2?: string;
    title_i2?: string;
    errorMsg_i2?: string;
    login_i2?: boolean;
    optional_i2?: boolean;
}
export default function DoubleInput(props: localProps) {
    const { t } = useTranslation("common", { keyPrefix: "doubleInput" });

    const loginResp1 = useLoginDebounce(props.value_i1, 200, props.login_i1 ?? false);
    const loginResp2 = useLoginDebounce(props.value_i2, 200, props.login_i2 ?? false);
    const [style, setStyle] = useState<(CSSProperties | undefined)[]>([undefined, undefined]);

    const origValues = useRef([props.value_i1, props.value_i2]);

    useEffect(() => {
        setStyle([
            loginResp1.available
                ? {
                      border: "2px solid var(--blue-base)",
                      padding: "6px 7px",
                  }
                : {
                      border: "2px solid #df4f5d",
                      padding: "6px 7px",
                  },
            style[1],
        ]);
        // eslint-disable-next-line
    }, [loginResp1.available]);

    useEffect(() => {
        setStyle([
            style[0],
            loginResp2.available
                ? {
                      border: "2px solid var(--blue-base)",
                      padding: "6px 7px",
                  }
                : {
                      border: "2px solid #df4f5d",
                      padding: "6px 7px",
                  },
        ]);
        // eslint-disable-next-line
    }, [loginResp2.available]);

    return (
        <div className={styles.double_input_wrapper}>
            <div className={styles.double_input}>
                <label htmlFor={props.id_i1} className={styles.input_label} title={props.text_i1}>
                    {props.text_i1}
                </label>

                <input
                    id={props.id_i1}
                    className={styles.input}
                    value={props.value_i1}
                    onChange={props.change}
                    type={props.password_i1 ? "password" : props.type_i1 ?? "text"}
                    placeholder={props.placeholder_i1}
                    autoComplete={props.password_i1 ? "new-password" : "off"}
                    required={props.optional_i1 ? false : true}
                    pattern={props.pattern_i1 ?? ".*"}
                    title={props.title_i1 ?? ""}
                    onInvalid={(e) => e.currentTarget.setCustomValidity(props.errorMsg_i1 ?? t("onInvalid"))}
                    onInput={(e) => e.currentTarget.setCustomValidity("")}
                    spellCheck={false}
                    style={props.login_i1 !== true ? {} : props.value_i1 === origValues.current[0] ? {} : style[0]}
                ></input>
            </div>
            <div className={styles.double_input}>
                <label htmlFor={props.id_i2} className={styles.input_label} title={props.text_i2}>
                    {props.text_i2}
                </label>

                <input
                    id={props.id_i2}
                    className={styles.input}
                    value={props.value_i2}
                    onChange={props.change_i2 ?? props.change}
                    type={props.password_i2 ? "password" : props.type_i2 ?? "text"}
                    placeholder={props.placeholder_i2}
                    autoComplete={props.password_i2 ? "new-password" : "off"}
                    required={props.optional_i2 ? false : true}
                    pattern={props.pattern_i2 ?? ".*"}
                    title={props.errorMsg_i2 ?? ""}
                    onInvalid={(e) => e.currentTarget.setCustomValidity(props.errorMsg_i2 ?? t("onInvalid"))}
                    onInput={(e) => e.currentTarget.setCustomValidity("")}
                    spellCheck={false}
                    style={props.login_i2 !== true ? {} : props.value_i2 === origValues.current[1] ? {} : style[1]}
                ></input>
            </div>
        </div>
    );
}
