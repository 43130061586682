import { CommissionForAuditorCompany } from "api/entities/commission";
import { State } from "api/entities/enums";
import NewOffer from "./NewOffer";
import Offers from "./Offers";

export default function ExpandedRow(props: {
    data: CommissionForAuditorCompany;
    expand: boolean;
    reloadOneRow: () => void;
}) {
    return (
        <>
            {props.data.num_offers === 0 && props.data.commission_state === State.pending ? (
                <NewOffer {...props} />
            ) : (
                <Offers {...props} />
            )}
        </>
    );
}
