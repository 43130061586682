import { addressApiService } from "./endpoints/address";
import { auditorApiService } from "./endpoints/auditor";
import { auditorCoApiService } from "./endpoints/auditorCo";
import { auditorManagerApiService } from "./endpoints/auditorManager";
import { authApiService } from "./endpoints/auth";
import { companyApiService } from "./endpoints/company";
import { dispatcherApiService } from "./endpoints/dispatcher";
import { dispatcherCoApiService } from "./endpoints/dispatcherCo";
import { priceApiService } from "./endpoints/price";
import { serviceApiService } from "./endpoints/service";
import { userApiService } from "./endpoints/user";
import { contractApiService } from "./endpoints/contract";
import { commissionApiService } from "./endpoints/commission";
import { offerApiService } from "./endpoints/offer";
import { fileApiService } from "./endpoints/file";
import { useEffect, useRef, useState } from "react";
import { LoginCheckResponse } from "./entities/user";
import { commissionGroupApiService } from "./endpoints/commission_group";

/**
 * ### **Exports**:
 * ### **_Hooks_**:
        const useLoginDebounce:
            (login: string, wait?: number, unlocked?: boolean) => LoginCheckResponse

                - Function to debounce login checking in user registration components
                - The API call for the last update of the 'login' parameter will always be executed after max. 'wait' ms
                
                Parameters:
                    login    - unsername to check if it exists
                    wait     - [Optional] Minimum time between API calls in milliseconds                [default: 200]
                    unlocked - [Optional] Flag determining if the api calls are blocked from executing  [default: true]
 * ### **_Objects_**:
            const api - Object containing all the API functions from 'src/api/endpoints'
 */
export const api = {
    ...addressApiService,
    ...serviceApiService,
    ...userApiService,
    ...auditorCoApiService,
    ...companyApiService,
    ...dispatcherCoApiService,
    ...auditorApiService,
    ...auditorManagerApiService,
    ...dispatcherApiService,
    ...priceApiService,
    ...authApiService,
    ...commissionApiService,
    ...contractApiService,
    ...offerApiService,
    ...fileApiService,
    ...commissionGroupApiService,

    DEFAULT_DEBOUNCE_DELAY: 200,
};

export default api;

export const useLoginDebounce = (
    login: string,
    wait: number = api.DEFAULT_DEBOUNCE_DELAY,
    unlocked: boolean = true,
) => {
    const [data, setData] = useState<LoginCheckResponse>({});
    const first = useRef(true);
    const apiCall = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (unlocked && !first.current) {
            clearTimeout(apiCall.current);

            apiCall.current = setTimeout(() => {
                api.checkLoginAvailability(login).then((res) => setData(res.data));
            }, wait);
        } else first.current = false;
    }, [login, unlocked, wait]);

    return data;
};
