import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "commons/Registration/SubmitButton/SubmitButton";
import { AddressCreate } from "api/entities/bases/address";
import api from "api";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import RegisterNewNominatimAddr from "commons/Registration/RegisterNewNominatimAddr";
/* import NotificationPopUp from 'commons_new/NotificationPopUp/NotificationPopUp'; */

export default function Address() {
    const { t } = useTranslation("adminView", { keyPrefix: "register.address" });
    const { handleErrors } = useErrorHandling();
    const [loading, setLoading] = useState(false);
    /* const [notifText, setNotifText] = useState(''); */

    const [inputs, setInputs] = useState<AddressCreate>({
        name: "",
        longitude: 0,
        latitude: 0,
    });

    const clearInputs = () => {
        setInputs({
            name: "",
            longitude: 0,
            latitude: 0,
        });
    };

    const [button, setButton] = useState({
        success: false,
    });

    const [err, setErr] = useState("");

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (inputs.name.length === 0) {
            setErr(t("nameErr") ?? "");
            return;
        }
        if (inputs.latitude === 0) {
            setErr(t("mapErr") ?? "");
            return;
        }

        setLoading(true);
        api.registerAddress(inputs).then((res) => {
            if (handleErrors(res.error).ok) {
                clearInputs();
                setLoading(false);
                setButton({ success: true });
                setErr("");
            } else {
                setLoading(false);
                setButton({ success: false });
            }
        });
    };

    return (
        <>
            {/* <NotificationPopUp text='Użytkownik został stworzony poprawnie'/> */}
            <form onSubmit={handleSubmit} autoComplete={"off"}>
                <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                    <RegisterNewNominatimAddr
                        value={{ name: inputs.name, lat: inputs.latitude, lng: inputs.longitude }}
                        htmlId="address"
                        setValue={(name: string, lat: number, lng: number) => {
                            setInputs({ name: name, latitude: lat, longitude: lng });
                            setErr("");
                        }}
                        placeholder={t("name") ?? "Nazwa"}
                        label={t("name") ?? "Nazwa"}
                    />

                    {err !== "" && <div style={{ textAlign: "center", color: "#df4f5d" }}>{err}</div>}
                    <SubmitButton loading={loading} success={button.success} />
                </div>
            </form>
        </>
    );
}
