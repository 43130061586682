import backend, { QueryParams } from "api/backend";
import { AddressBase, AddressCreate, AddressEdit } from "api/entities/bases/address";
import { AddressFilter } from "api/filters/address";

/**
 *  ## Object containing functions that fetch API endpoints related to addresses 
 *  ### **_addressAPIService_** functions:
        fetchAddresses: 
            (q?: QueryParams<AddressBase[], AddressFilter>) 
                => Promise<backendResponse<AddressBase[], AddressFilter>>
    <br/>
        fetchAddress: 
            (id: string [UUID]) 
                => Promise<backendResponse<AddressBase, undefined>>
    <br/>
        registerAddress: 
            (addr: AddressCreate) 
                => Promise<backendResponse<AddressBase, undefined>>
    <br/>
        removeAddress: 
            (id: string [UUID]) 
                => Promise<backendResponse<null, undefined>>
    <br/>
        editAddress: 
            (idL string [UUID], edit: AddressEdit) 
                => Promise<backendResponse<AddressBase, undefined>> 
 */
export const addressApiService = {
    fetchAddresses: (q?: QueryParams<AddressBase[], AddressFilter>) => {
        return backend.get(`/addresses`, q);
    },
    fetchAddress: (id: string) => {
        return backend.get<AddressBase>(`/address/${id}`);
    },
    registerAddress: (addr: AddressCreate) => {
        return backend.post<AddressBase>(`/address`, addr);
    },
    removeAddress: (id: string) => {
        return backend.delete(`/address/${id}`);
    },
    editAddress: (id: string, edit: AddressEdit) => {
        return backend.put<AddressBase>(`/address/${id}`, edit);
    },
};
