import api from "api";
import { AuditorCoWithAddress } from "api/entities/auditorCo";
import { DispatcherCoWithAddress } from "api/entities/dispatcherCo";
import Button from "commons/Button/Button";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import SingleInput from "commons/Registration/SingleInput";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./UserProfile.module.css";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

interface inputs {
    curPwd: string;
    newPwd: string;
}

export default function UserProfile() {
    const { t } = useTranslation("common", { keyPrefix: "profile" });
    const { handleErrors } = useErrorHandling();

    const audiCompany = useRef<AuditorCoWithAddress>();
    const dispCompany = useRef<DispatcherCoWithAddress>();
    const [isFetched, setIsFetched] = useState(false);

    const [pwdChange, setPwdChange] = useState(false);
    const [inputs, setInputs] = useState<inputs>({ curPwd: "", newPwd: "" });
    const [errMsg, setErrMsg] = useState("");

    const clearInputs = () => {
        setInputs({ curPwd: "", newPwd: "" });
    };

    useEffect(() => {
        if (!isFetched) {
            const aspects = sessionStorageHelper.getAspects();

            if (aspects.includes("operator") || aspects.includes("worker"))
                api.fetchAuditorCompanyWithAddress(sessionStorageHelper.getAuditorCoId()).then((x) => {
                    if (handleErrors(x.error).ok) audiCompany.current = x.data;
                    setIsFetched(true);
                });
            else if (aspects.includes("dispatcher"))
                api.fetchDispatcherCompanyWithAddress(sessionStorageHelper.getDispatcherCoId()).then((x) => {
                    if (handleErrors(x.error).ok) dispCompany.current = x.data;
                    setIsFetched(true);
                });
        }
    }, [isFetched, handleErrors]);

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        if (errMsg !== "") setErrMsg("");
        const value: string = event.currentTarget.value;
        setInputs({
            ...inputs,
            [event.currentTarget.id]: value,
        });
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        api.editUserPassword(sessionStorageHelper.getUserId(), inputs.curPwd, inputs.newPwd).then((x) => {
            const e = handleErrors(x.error, false);
            if (e.ok) {
                setErrMsg(t("success") ?? "");
                setPwdChange(false);
                clearInputs();
            } else setErrMsg(e.err_str);
        });
    };

    return (
        <div className={styles.content}>
            <div className={styles.title_wrapper}>
                <h1 className={styles.title}>{t("title")}</h1>
            </div>

            <div className={styles.line} />

            <div className={styles.profile}>
                <div className={styles.profile_info} style={{ fontSize: "2rem" }}>
                    {sessionStorageHelper.getFullName()}
                </div>

                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th className={styles.th}>{t("phone")}</th>
                            <th className={styles.th}>{t("email")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={styles.td}>{sessionStorageHelper.getPhone()}</td>
                            <td className={styles.td}>{sessionStorageHelper.getEmail()}</td>
                        </tr>
                    </tbody>
                </table>

                {audiCompany.current !== undefined && (
                    <>
                        <div className={styles.line} />
                        <div className={styles.profile_info} style={{ fontSize: "2rem" }}>
                            {audiCompany.current?.company_name}
                        </div>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.th}>{t("address")}</th>
                                    <th className={styles.th}>{t("taxNo")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={styles.td}>{audiCompany.current.address.name}</td>
                                    <td className={styles.td}>{audiCompany.current.tax_id}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}
                {dispCompany.current !== undefined && (
                    <>
                        <div className={styles.line} />
                        <div className={styles.profile_info} style={{ fontSize: "2rem" }}>
                            {dispCompany.current?.company_name}
                        </div>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.th}>{t("address")}</th>
                                    <th className={styles.th}>{t("taxNo")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className={styles.td}>{dispCompany.current.address.name}</td>
                                    <td className={styles.td}>{dispCompany.current.tax_id}</td>
                                </tr>
                            </tbody>
                        </table>
                    </>
                )}

                <div className={styles.line} />

                {errMsg !== "" && <div className={styles.profile_info}>{errMsg}</div>}

                {pwdChange ? (
                    <form onSubmit={handleSubmit} style={{ padding: "0.5rem" }}>
                        <div
                            style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                maxWidth: "100%",
                                width: "400px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: "10px",
                            }}
                        >
                            <SingleInput
                                text="Stare hasło"
                                id={"curPwd"}
                                value={inputs.curPwd}
                                change={handleChange}
                                placeholder={t("oldPwd")}
                                password
                            />
                            <SingleInput
                                text="Nowe hasło"
                                id={"newPwd"}
                                value={inputs.newPwd}
                                change={handleChange}
                                placeholder={t("newPwd")}
                                password
                            />
                            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                <div className={styles.profile_info}>
                                    <Button label={t("confirm")} type="submit" />
                                </div>
                                <div className={styles.profile_info}>
                                    <Button label={t("cancel")} id={"delete"} click={() => setPwdChange(false)} />
                                </div>
                            </div>
                        </div>
                    </form>
                ) : (
                    <div className={styles.profile_info}>
                        <Button
                            label={t("changePwd")}
                            click={() => {
                                if (errMsg !== "") setErrMsg("");
                                setPwdChange(true);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
