import api from "api";
import { backendResponse } from "api/backend";
import { AddressCreate, AddressRemove } from "api/entities/bases/address";
import { CommissionBase, CommissionCreate, CommissionEdit } from "api/entities/bases/commission";
import { CommissionForDispatcherCompany } from "api/entities/commission";
import { HTTPErrorType } from "api/entities/error";
import moment from "moment";

/* Used in 
    DispatcherView:
        NewCommission    
*/

type Commission = {
    addr_primary_id: string;
    addr_primary_name: string;
    addr_primary_latitude: number;
    addr_primary_longitude: number;

    addr_secondary_id: string;
    addr_secondary_name: string;
    addr_secondary_latitude: number;
    addr_secondary_longitude: number;

    dispatcher_id: string;
    target_date: Date;
    service_id: string;
    service_name: string;
    train_no: string;
    contact_person: string;
    contact_phone: string;
    locomotive: string;
    comment: string;
    group_id?: string;
};

const getDefaultFormData = (dispatcher_id?: string): Commission => {
    return {
        addr_primary_id: "",
        addr_primary_name: "",
        addr_primary_latitude: 0.0,
        addr_primary_longitude: 0.0,

        addr_secondary_id: "",
        addr_secondary_name: "",
        addr_secondary_latitude: 0.0,
        addr_secondary_longitude: 0.0,

        dispatcher_id: dispatcher_id ?? "",
        target_date: moment(new Date()).add(15, "m").toDate(),
        service_id: "",
        service_name: "",
        train_no: "",
        contact_person: "",
        contact_phone: "",
        locomotive: "",
        comment: "",
    };
};

const parseCommission = (c?: Commission | CommissionForDispatcherCompany): Commission => {
    if (c === undefined) return getDefaultFormData();
    if ((c as Commission).addr_primary_latitude !== undefined) return c as Commission;
    const cb = c as CommissionForDispatcherCompany;
    return {
        addr_primary_id: cb.addr_primary_id,
        addr_primary_name: cb.address_primary.name,
        addr_primary_latitude: cb.address_primary.latitude,
        addr_primary_longitude: cb.address_primary.longitude,

        addr_secondary_id: cb.addr_secondary_id ?? "",
        addr_secondary_name: cb.address_secondary?.name ?? "",
        addr_secondary_latitude: cb.address_secondary?.latitude ?? 0.0,
        addr_secondary_longitude: cb.address_secondary?.longitude ?? 0.0,

        dispatcher_id: cb.dispatcher_id,
        target_date: moment(cb.target_date).toDate(),
        service_id: cb.service_id,
        service_name: cb.service.name,
        train_no: cb.train_no,
        contact_person: cb.contact_person ?? "",
        contact_phone: cb.contact_phone ?? "",
        locomotive: cb.locomotive ?? "",
        comment: cb.comment ?? "",
        group_id: cb.group_id,
    };
};

const getFormData = (c?: Commission | CommissionForDispatcherCompany, dispatcher_id?: string): Commission => {
    if (c === undefined) return getDefaultFormData(dispatcher_id);
    return {
        ...parseCommission(c),
        dispatcher_id: dispatcher_id ?? c.dispatcher_id,
    };
};

const newCopyFormData = (c?: Commission | CommissionForDispatcherCompany, dispatcher_id?: string): Commission => {
    if (c === undefined) return getDefaultFormData(dispatcher_id);
    return {
        ...getFormData(c, dispatcher_id),
        target_date: moment(new Date()).add(15, "m").toDate(),
        service_id: "",
        service_name: "",
        comment: "",
    };
};

const handleSubmit = async (
    c: Commission,
    f: File[],
): Promise<{ res: backendResponse<CommissionBase>; errs: HTTPErrorType[] }> => {
    /* Functions delivering data should ensure that if address is selected - name is empty
        same when creating new address - id is empty */

    const data: Commission = c;
    data.contact_phone = data.contact_phone.replace(/\s/g, "");

    const com = {
        addr_primary:
            c.addr_primary_id === ""
                ? {
                      name: c.addr_primary_name,
                      latitude: c.addr_primary_latitude,
                      longitude: c.addr_primary_longitude,
                  }
                : c.addr_primary_id,

        addr_secondary:
            c.addr_secondary_id === ""
                ? c.addr_secondary_name !== ""
                    ? {
                          name: c.addr_secondary_name,
                          latitude: c.addr_secondary_latitude,
                          longitude: c.addr_secondary_longitude,
                      }
                    : undefined
                : c.addr_secondary_id,

        target_date: c.target_date,
        dispatcher_id: c.dispatcher_id,
        train_no: c.train_no,
        contact_person: c.contact_person,
        contact_prefix_phone: data.contact_phone.indexOf("+") === -1 ? "+48" : data.contact_phone.substring(0, 3),
        contact_phone: data.contact_phone.indexOf("+") === -1 ? data.contact_phone : data.contact_phone.substring(3),
        service_id: c.service_id,
        locomotive: c.locomotive,
        comment: c.comment,
        group_id: c.group_id,
    } as CommissionCreate;

    let errs: HTTPErrorType[] = [];
    const x = await api.registerCommission(com);

    if (x.ok) {
        if (f.length > 0)
            await api.registerCommissionAttachment(f, x.data.id).then((res) => {
                if (res.error) {
                    errs.push(res.error);
                }
            });
    }

    return { res: x, errs: errs };
};

const handleEdit = async (
    original: CommissionForDispatcherCompany,
    c: Commission,
    f: File[],
): Promise<{ res: backendResponse<CommissionBase>; errs: HTTPErrorType[] }> => {
    const cb = parseCommission(original);

    const addr_sec =
        cb.addr_secondary_id === ""
            ? c.addr_secondary_id === ""
                ? c.addr_secondary_name !== "" &&
                  c.addr_secondary_latitude !== 0.0 &&
                  c.addr_secondary_longitude !== 0.0
                    ? ({
                          name: c.addr_secondary_name,
                          latitude: c.addr_secondary_latitude,
                          longitude: c.addr_secondary_longitude,
                      } as AddressCreate)
                    : undefined
                : c.addr_secondary_id
            : c.addr_secondary_id === ""
              ? ({
                    removed_addr_id: cb.addr_secondary_id,
                } as AddressRemove)
              : cb.addr_secondary_id !== c.addr_secondary_id
                ? c.addr_secondary_id
                : undefined;

    const edit = {
        addr_primary:
            c.addr_primary_id === ""
                ? {
                      name: c.addr_primary_name,
                      latitude: c.addr_primary_latitude,
                      longitude: c.addr_primary_longitude,
                  }
                : cb.addr_primary_id !== c.addr_primary_id
                  ? c.addr_primary_id
                  : undefined,
        addr_secondary: addr_sec,
        comment: cb.comment !== c.comment ? c.comment : undefined,
        contact_person: cb.contact_person !== c.contact_person ? c.contact_person : undefined,
        contact_prefix_phone:
            cb.contact_phone !== c.contact_phone
                ? c.contact_phone.indexOf("+") === -1
                    ? "+48"
                    : c.contact_phone.substring(0, 3)
                : undefined,
        contact_phone:
            cb.contact_phone !== c.contact_phone
                ? c.contact_phone.indexOf("+") === -1
                    ? c.contact_phone
                    : c.contact_phone.substring(3)
                : undefined,
        locomotive: cb.locomotive !== c.locomotive ? c.locomotive : undefined,
        target_date: cb.target_date !== c.target_date ? c.target_date : undefined,
        train_no: cb.train_no !== c.train_no ? c.train_no : undefined,
    } as CommissionEdit;

    const x = await api.editCommission(original.id, edit);

    let errs: HTTPErrorType[] = [];
    if (x.ok) {
        if (f.length > 0)
            await api.registerCommissionAttachment(f, x.data.id).then((res) => {
                if (res.error) {
                    errs.push(res.error);
                }
            });
    }

    return { res: x, errs: errs };
};

const newCommissionService = {
    getDefaultFormData,
    getFormData,
    newCopyFormData,
    parseCommission,
    handleSubmit,
    handleEdit,
};

export { newCommissionService, Commission };
