import Button from "commons/Button/Button";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./NewAttachments.module.css";

export default function NewAttachments(props: {
    files: File[];
    addFile: (f: File) => void;
    removeFile: (f: File) => void;
}) {
    const { t } = useTranslation("common", { keyPrefix: "attachments" });

    const inputRef = useRef<HTMLInputElement | null>(null);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            for (let i = 0; i < event.currentTarget.files.length; i++) {
                let f = event.currentTarget.files.item(i);
                if (f !== null) {
                    props.addFile(f);
                }
            }
        }
    };

    const handleClick = () => {
        if (inputRef.current) inputRef.current.click();
    };

    return (
        <div className={styles.content_wrapper}>
            <div className={styles.content}>
                <div className={styles.label}>{t("attachments")}</div>

                {props.files.map((e) => {
                    return (
                        <div
                            style={{ display: "flex", padding: "0 0 10px 10px", textOverflow: "clip" }}
                            key={`${e.name}`}
                        >
                            <Button
                                click={() => {
                                    props.removeFile(e);
                                }}
                                label={t("deleteAttachment")}
                                id={"delete"}
                            />
                            <span
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "clip",
                                    whiteSpace: "nowrap",
                                    padding: "10px",
                                    maxWidth: "300px",
                                }}
                            >
                                {" "}
                                {e.name}{" "}
                            </span>
                        </div>
                    );
                })}

                <div style={{ padding: "0 0 10px 10px" }}>
                    <input
                        type={"file"}
                        name={"file"}
                        ref={inputRef}
                        onChange={changeHandler}
                        multiple
                        style={{ display: "none" }}
                    />
                    <Button label={t("newAttachment")} click={handleClick} id={"success"} />
                </div>
            </div>
        </div>
    );
}
