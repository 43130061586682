import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Auditor from "./forms/Auditor";
import styles from "./Register.module.css";
import { registerHelper } from "./registerHelper";

export default function Register() {
    const location = useLocation();
    const { t } = useTranslation("adminView", { keyPrefix: "register" });

    const [state, setState] = useState<{ id: any; component: any }>(() => {
        if (location.state != null) {
            let x = registerHelper.getForm(location.state.id);
            return { id: x?.mode, component: x?.component };
        } else return { id: "auditor", component: <Auditor /> };
    });

    useEffect(() => {
        document.title = `${t("title")} | Raily Marketplace`;
    }, [t]);

    return (
        <div className={styles.content}>
            <div className={styles.heading}>
                <h1>{t("title")}</h1>
            </div>

            <div className={styles.form_choice}>
                {registerHelper.getForms().map((x) => {
                    return (
                        <button
                            key={`${x.mode} + ${x.id}`}
                            type="button"
                            className={styles.button}
                            id={state.id === x.mode ? styles.button_active : ""}
                            onClick={(e) => {
                                setState({ id: x.mode, component: x.component });
                            }}
                        >
                            {t(`${x.mode}.`)}
                        </button>
                    );
                })}
            </div>

            <div className={styles.form_wrapper}>
                <div className={styles.form}>
                    <div className={styles.form_header}>{t(`${state.id}.`)}</div>

                    {state.component}
                </div>
            </div>
        </div>
    );
}
