import backend, { QueryParams } from "api/backend";
import {
    Offer,
    OfferForAuditor,
    OfferForAuditorManager,
    OfferFull,
    OfferForCommission,
    ManeuversAddresses,
} from "api/entities/offer";
import { OfferBase, OfferCreate, OfferEdit } from "api/entities/bases/offer";
import { PriceCalculationResponse } from "api/entities/price";
import { OfferFilter } from "api/filters/offer";

/**
 *  ## Object containing functions that fetch API endpoints related to offers
 *  ### **_offerApiService_** functions:
        fetchOffers:
            (q?: QueryParams<Offer[], OfferFilter>)
                => Promise<backendResponse<Offer[], OfferFilter>>
    <br/>
        fetchOffer:
            (id: string [UUID])
                => Promise<backendResponse<OfferFull, undefined>>
    <br/>
        fetchOffersWithFullInfo:
            (q?: QueryParams<OfferFull[], OfferFilter>)
                => Promise<backendResponse<OfferFull[], OfferFilter>>
    <br/>
        fetchOffersForAuditor:
            (auditor_id: string [UUID], q?: QueryParams<OfferForAuditor[], undefined>)
                => Promise<backendResponse<OfferForAuditor[], undefined>>
    <br/>
        fetchOfferForAuditor:
            (auditor_id: string [UUID], offer_id: string [UUID])
                => Promise<backendResponse<OfferForAuditor, undefined>>
    <br/>
        fetchOffersForAuditorManager:
            (auditor_manager_id: string [UUID], q?: QueryParams<OfferFull[], OfferFilter>)
                => Promise<backendResponse<OfferFull[], OfferFilter>>
    <br/>
        fetchOffersForCommission:
            (commission_id: string [UUID], q?: QueryParams<OfferForCommission[], undefined>)
                => Promise<backendResponse<OfferForCommission[], undefined>>
    <br/>
        fetchOffersForAuditorCoByCommission:
            (   
                auditor_co_id: string [UUID],
                commission_id: string [UUID],
                q?: QueryParams<OfferForAuditorManager, undefined>
            ) => Promise<backendResponse<OfferForAuditorManager, undefined>>
    <br/>
        fetchOffersAcceptedForCommission:
            (commission_id: string [UUID], q?: QueryParams<OfferForAuditor[], undefined>)
                => Promise<backendResponse<OfferForAuditor[], undefined>>
    <br/>
        registerOffer:
            (offer: OfferCreate)
                => Promise<backendResponse<OfferBase, undefined>>
    <br/>
        removeOffer:
            (id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editOffer:
            (id: string [UUID], edit: OfferEdit)
                => Promise<backendResponse<OfferBase, undefined>>
    <br/>
        calculateOfferPrice:
            (offer: OfferCreate)
                => Promise<backendResponse<PriceCalculationResponse, undefined>>
 */
export const offerApiService = {
    fetchOffers: (q?: QueryParams<Offer[], OfferFilter>) => {
        return backend.get(`/offers`, q);
    },
    fetchOffer: (id: string) => {
        return backend.get<OfferFull>(`/offer/${id}`);
    },
    fetchOffersWithFullInfo: (q?: QueryParams<OfferFull[], OfferFilter>) => {
        return backend.get(`/offers/full`, q);
    },
    fetchOffersForAuditor: (auditor_id: string, q?: QueryParams<OfferForAuditor[]>) => {
        return backend.get(`/offers/auditor/${auditor_id}`, q);
    },
    fetchActiveOffersForAuditor: (auditor_id: string, q?: QueryParams<OfferForAuditor[]>) => {
        return backend.get(`/offers/active/auditor/${auditor_id}`, q);
    },
    fetchInactiveOffersForAuditor: (auditor_id: string, q?: QueryParams<OfferForAuditor[]>) => {
        return backend.get(`/offers/inactive/auditor/${auditor_id}`, q);
    },
    fetchOfferForAuditor: (auditor_id: string, offer_id: string) => {
        return backend.get<OfferForAuditor>(`/offer/${offer_id}/auditor/${auditor_id}`);
    },
    fetchOffersForAuditorManager: (auditor_manager_id: string, q?: QueryParams<OfferFull[], OfferFilter>) => {
        return backend.get(`/offers/auditor-manager/${auditor_manager_id}`, q);
    },
    fetchOffersForCommission: (commission_id: string, q?: QueryParams<OfferForCommission[]>) => {
        return backend.get(`/offers/commission/${commission_id}`, q);
    },
    fetchOffersForAuditorCoByCommission: (
        auditor_co_id: string,
        commission_id: string,
        q?: QueryParams<OfferForAuditorManager>,
    ) => {
        return backend.get(`/offers/auditor-company/${auditor_co_id}/commission/${commission_id}`, q);
    },
    fetchOffersAcceptedForCommission: (commission_id: string, q?: QueryParams<OfferForAuditor[]>) => {
        return backend.get(`/offers/dispatcher/commission/${commission_id}`, q);
    },
    registerOffer: (offer: OfferCreate) => {
        return backend.post<OfferBase>(`/offer`, offer);
    },
    removeOffer: (id: string) => {
        return backend.delete(`/offer/${id}`);
    },
    editOffer: (id: string, edit: OfferEdit) => {
        return backend.put<OfferBase>(`/offer/${id}`, edit);
    },
    calculateOfferPrice: (offer: OfferCreate) => {
        return backend.post<PriceCalculationResponse>(`/offer/calculate-price`, offer);
    },
    calculateManeuvers: (addrs: ManeuversAddresses) => {
        return backend.post<{ lat: string; lng: string }[]>(`/offer/calculate-maneuvers`, addrs);
    },
};
