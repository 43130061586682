import { useEffect, useRef, useState } from "react";
import Spinner from "commons/Spinner/Spinner";
import styles from "styles/UserListing.module.css";
import { commissionsHelper } from "./commissionHelper";
import api from "api";
import { OfferForAuditor } from "api/entities/offer";
import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { useTranslation } from "react-i18next";
import Statuses from "commons/Statuses";
import Pagination from "commons/Pagination";
import Button from "commons/Button/Button";
import CustomCssTransition from "commons/CustomCssTransition";
import SecondaryRow from "./SecondaryRow";
import { State } from "api/entities/enums";
import { useErrorHandling } from "commons/hooks/useErrorHandling";

export default function CommissionsInactive() {
    const { t } = useTranslation("auditorView", { keyPrefix: "commissions" });
    const { handleErrors } = useErrorHandling();

    useEffect(() => {
        document.title = `${t("title")} | Raily Marketplace`;
    }, [t]);

    // Na podstawie tego wyświetla się spinner ładowania lub tabela
    const [isFetched, setIsFetched] = useState(false);

    // Do przechowywania zfetchowanych danych i przekazania tabeli
    const fetchedData = useRef<OfferForAuditor[]>([]);

    /* Pagination handling */
    const page = useRef(1);
    const lastGoodPage = useRef(1);

    const handleRefresh = () => {
        setIsFetched(false);
    };

    // Pobiera dane po załadowaniu komponentu i ustawia isFetched na true
    useEffect(() => {
        if (!isFetched) {
            try {
                api.fetchInactiveOffersForAuditor(sessionStorageHelper.getAuditorId(), {
                    page: page.current,
                }).then((x) => {
                    handleErrors(x.error);
                    if (x.data.length === 0 && page.current !== 1) page.current = lastGoodPage.current;
                    else {
                        fetchedData.current = x.data;
                        lastGoodPage.current = page.current;
                    }
                    setIsFetched(true);
                });
            } catch (e) {
                console.log(e);
            }
        }
    }, [isFetched, handleErrors]);

    return (
        <div className={styles.content}>
            <div className={styles.content_row}>
                <h1 className={styles.h1}>{t("title")}</h1>
            </div>

            <div className={styles.content_row}>
                <Statuses
                    statuses={[
                        {
                            label: t("statuses.completed"),
                            color: "var(--status-completed)",
                        },
                        {
                            label: t("statuses.canceled"),
                            color: "var(--status-canceled)",
                        },
                    ]}
                    titleLabel={t("statuses.title")}
                />

                <div style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}>
                    <Button type="button" label={t("refresh")} click={handleRefresh} height={"36px"} />
                </div>
            </div>

            <div className={styles.table_wrapper}>
                <Spinner isFetched={isFetched} size={"80px"}>
                    <table className={styles.table}>
                        <thead className={styles.thead}>
                            <tr>
                                <th scope="col" className={styles.th} style={{ width: "10px" }}>
                                    {/* Status */}
                                </th>
                                <th scope="col" className={styles.th}>
                                    {t("data.targetDate")}
                                </th>
                                <th scope="col" className={styles.th}>
                                    {t("data.identifier")}
                                </th>
                                <th scope="col" className={styles.th}>
                                    {t("data.trainNo")}
                                </th>
                                <th scope="col" className={styles.th}>
                                    {t("data.address")}
                                </th>
                                <th scope="col" className={styles.th}>
                                    {t("data.addressSecondary")}
                                </th>
                                <th scope="col" className={styles.th}>
                                    {t("data.locomotive")}
                                </th>
                                <th scope="col" className={styles.th}>
                                    {t("data.service")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedData.current
                                .sort((a, b) => {
                                    if (
                                        Number.parseInt(a.commission.identifier) >
                                        Number.parseInt(b.commission.identifier)
                                    )
                                        return -1;
                                    else return 1;
                                })
                                .map((x) => {
                                    return <Row key={x.commission_id} data={x} />;
                                })}
                        </tbody>
                    </table>
                </Spinner>
            </div>
            <Pagination
                page={lastGoodPage.current}
                change={(n: number) => {
                    page.current = n;
                    setIsFetched(false);
                }}
                paddingRight="20px"
            />
        </div>
    );
}

function Row(props: { data: OfferForAuditor }) {
    const [expand, setExpand] = useState(false);
    const [item, setItem] = useState<OfferForAuditor>(props.data);
    const eh = useErrorHandling();

    const reloadOneRow = () => {
        try {
            api.fetchOfferForAuditor(props.data.auditor_id, props.data.id).then((x) => {
                if (eh.handleErrors(x.error).ok) {
                    setItem(x.data);
                    setExpand(true);
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <>
            <tr className={styles.tr} onClick={() => setExpand(!expand)}>
                {/* Primary row */}
                <PrimaryRow data={item} />
            </tr>

            {/* Secondary expandable row */}
            <CustomCssTransition in={expand} trMode={true} colSpan={8}>
                <SecondaryRow data={item} reloadOneRow={reloadOneRow} expand={expand} />
            </CustomCssTransition>
        </>
    );
}

function PrimaryRow(props: { data: OfferForAuditor }) {
    const { t } = useTranslation("auditorView", { keyPrefix: "commissions" });

    const handleStatusColor = (item: OfferForAuditor): string => {
        if (!item.accepted_by_auditor && props.data.commission.commission_state === State.pending)
            return "var(--status-new)";
        else {
            switch (props.data.commission.commission_state) {
                case "pending":
                    return "var(--status-pending)";
                case "active":
                    return "var(--status-accepted)";
                case "completed":
                    return "var(--status-completed)";
                case "canceled":
                    return "var(--status-canceled)";
            }
        }
        return "#000";
    };

    return (
        <>
            <td className={styles.td} id={styles.status} data-label="Status">
                <div
                    className={styles.status}
                    style={{
                        backgroundColor: handleStatusColor(props.data),
                    }}
                ></div>
            </td>
            <td className={styles.td} data-label={t("data.targetDate")}>
                {commissionsHelper.getTargetDate(props.data)}
            </td>
            <td className={styles.td} data-label={t("data.identifier")}>
                {commissionsHelper.getID(props.data)}
            </td>
            <td className={styles.td} data-label={t("data.trainNo")}>
                {commissionsHelper.getTrainNo(props.data)}
            </td>
            <td className={styles.td} data-label={t("data.address")}>
                {commissionsHelper.getCommissionAddressName(props.data)}
            </td>
            <td className={styles.td} data-label={t("data.addressSecondary")}>
                {commissionsHelper.getCommissionSecondaryAddressName(props.data)}
            </td>
            <td className={styles.td} data-label={t("data.locomotive")}>
                {commissionsHelper.getLocomotive(props.data)}
            </td>
            <td className={styles.td} data-label={t("data.service")}>
                {commissionsHelper.getServices(props.data).name ?? "-"}
            </td>
        </>
    );
}
