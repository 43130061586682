export enum Role {
    admin,
    dispatcher,
    auditorManager,
    auditor,
    undefined,
}

export enum StateColor {
    pending = "yellow",
    active = "blue",
    completed = "green",
    canceled = "red",
}

export enum State {
    new = "new",
    pending = "pending",
    active = "active",
    completed = "completed",
    canceled = "canceled",
    commission_edited = "commission_edited",
    selected_offer_edited = "selected_offer_edited",
}

export enum ServiceCategory {
    uncategorized = "uncategorized",
    commute = "commute",
    auditor = "auditor",
}

export enum Currency {
    PLN = "PLN",
    USD = "USD",
    EUR = "EUR",
}

export enum CommuteMode {
    none = "none",
    toSingle = "toSingle",
    toDouble = "toDouble",
    fromSingle = "fromSingle",
    fromSingleToSingle = "fromSingleToSingle",
    fromSingleToDouble = "fromSingleToDouble",
    fromDouble = "fromDouble",
    fromDoubleToSingle = "fromDoubleToSingle",
    fromDoubleToDouble = "fromDoubleToDouble",
}

export enum WorkerType {
    TRAIN_DRIVER = "TRAIN_DRIVER",
    AUDITOR = "AUDITOR",
    POSITIONER = "POSITIONER",
    SHUNTER = "SHUNTER", // manewrowy
    INSTRUCTOR = "INSTRUCTOR",
    TRAIN_MANAGER = "TRAIN_MANAGER", // kierownik pociagu
    SIDING_VEHICLE_OPERATOR = "SIDING_VEHICLE_OPERATOR", // prowadzacy pojazdy w obrebie pocznicy
    PILOT = "PILOT",
    ECONOMIC_AND_WORK_TRAIN_MANAGER = "ECONOMIC_AND_WORK_TRAIN_MANAGER"
}

export const WorkerTypeList: WorkerType[] = [
    WorkerType.TRAIN_DRIVER,
    WorkerType.AUDITOR,
    WorkerType.POSITIONER,
    WorkerType.SHUNTER,
    WorkerType.INSTRUCTOR,
    WorkerType.TRAIN_MANAGER,
    WorkerType.SIDING_VEHICLE_OPERATOR,
    WorkerType.PILOT,
    WorkerType.ECONOMIC_AND_WORK_TRAIN_MANAGER,
];
