import { sessionStorageHelper } from "commons/Helpers/sessionStorageHelper";
import { Credentials, AuthResponse } from "./entities/auth";
import { HTTPErrorType } from "./entities/error";

export const backendURL = process.env.REACT_APP_BACKEND_URL;
export const nominatimURL = process.env.REACT_APP_ADDRESS_SEARCH_SERVICE_BASE_API_URL;

//eslint-disable-next-line
export interface GenericTypeObject<T, G, U> { }

// eslint-disable-next-line
export interface backendResponse<T, G = undefined> {
    ok: boolean;
    status: number;
    data: T;
    page?: number;
    pages?: number;
    error?: HTTPErrorType;
}

// eslint-disable-next-line
export interface QueryParams<T, G = undefined, P = T> {
    page?: number;
    size?: number;
    order_by?: keyof (P extends (infer U)[] ? U : P);
    descending?: boolean;
    filter?: G;
    statuses?: string[];
    additional_query?: { [key: string]: string };
    language?: string;
}

const unpack: <T, G = undefined>(res: Response) => Promise<backendResponse<T, G>> = async (response) => {
    let data;

    try {
        if (response.headers.get("content-type") === "application/json") data = await response.json();
        else data = await response.blob();
    } catch (e) {
        data = null;
    }

    return {
        ok: response.ok,
        status: response.status,
        data: data ? data.items ?? data : null,
        page: data ? data.page ?? 1 : null,
        pages: data ? data.pages ?? 1 : null,
        error: data ? data.detail : undefined,
    };
};

/** 
 *  ### **Exports**:
 *  ### **_Interfaces_**:
        interface GenericTypeObject<T,G,U> {}
            -   Empty helper interface, useful to pass generic types between components
   <br/>
        interface backendResponse<T,G = undefined> {
            ok: boolean;        - HTTP response ok flag
            status: number;     - HTTP response status
            data: T;            - Object of type 'T' returned by the Raily Marketplace API
            page?: number;      - [Optional] Page number if the API's response was paginated
            pages?: number;     - [Optional] Total number of pages available to fetch from the API
        }
    <br/>
        interface QueryParams<T,G = undefined> {
            page?: number;          - [Optional] Page number to fetch from the API
            size?: number;          - [Optional] Size of the page returned by the API
            order_by?: keyof (T extends (infer U)[] ? U : T);
                                    - [Optional] Key of an object of type 'T' to order the returned items by. 
                                                 If 'T' is an array, 'order_by' is a key of the array's element
            descending?: boolean;   - [Optional] Flag determining if the returned items are to be sorted in 
                                                 the descending order
            filter?: G;             - [Optional] Filter object of type 'G'
            statuses?: string[];    - [Optional] Status filter list. Used if the returned items are to be of many statuses 
        }
    ### **_Objects_**:
        const backend
            -   Object containing wrapper functions for the funtion 'fetch'
            -   Functions' url parameter is prefixed with 'REACT_APP_BACKEND_URL' string from '.env' file
    ### **_backend_** functions:
        post:
            async <T>(url: string, body: any) 
                => Promise<backendResponse<T, undefined>>
    <br/>
        put:
            async <T>(url: string, body: any) 
                => Promise<backendResponse<T, undefined>>
    <br/>
        get:
            async <T, G = undefined>(url: string, params?: QueryParams<T, G>) 
                => Promise<backendResponse<T, G>>
    <br/>
        delete:
            async (url: string) 
                => Promise<backendResponse<null, undefined>>
    <br/>
        login:
            async (url: string, credentials: Credentials) 
                => Promise<backendResponse<AuthResponse, undefined>>
    <br/>
        postFile:
            async (url: string, file: File) 
                => Promise<backendResponse<null, undefined>>
    <br/>
*   **post**, **put**, **get**, **delete**, **postFile** functions require *authorization token* to be stored in the
*   **sessionStorage** as *token*
**/
export const backend = {
    post: async <T>(url: string, body: any) => {
        return unpack<T>(
            await fetch(backendURL + url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + sessionStorageHelper.getToken(),
                },
                body: JSON.stringify(body),
            }),
        );
    },
    put: async <T>(url: string, body: any) => {
        return unpack<T>(
            await fetch(backendURL + url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + sessionStorageHelper.getToken(),
                },
                body: JSON.stringify(body),
            }),
        );
    },
    get: async <T, G = undefined, P = T>(url: string, params: QueryParams<T, G, P> = {}) => {
        const query_params = new URLSearchParams();

        if (params.page !== undefined) query_params.append("page", String(params.page));
        if (params.size !== undefined) query_params.append("size", String(params.size));
        if (params.order_by !== undefined) query_params.append("order_by", String(params.order_by));
        if (params.descending !== undefined) query_params.append("descending", params.descending ? "true" : "false");
        if (params.language !== undefined) query_params.append("language", params.language);
        if (params.statuses !== undefined)
            for (let s of params.statuses) {
                query_params.append("statuses", String(s));
            }

        var targetDate: Date | undefined = undefined;
        var targetDateEnd: Date | undefined = undefined;

        if (params.filter !== undefined && params.filter !== null) {
            for (let f in { ...params.filter })
                if (params.filter[f] !== undefined && params.filter[f] !== null)
                    if (params.filter[f] instanceof Date) {
                        if (f === "target_date") {
                            targetDate = params.filter[f] as Date;
                        }
                        if (f === "target_date_end") {
                            targetDateEnd = params.filter[f] as Date;

                            if ((targetDate) && targetDateEnd.getTime() === targetDate.getTime()) {
                                var newDate = new Date(targetDate.getTime() + 1000 * 60 * 60 * 24);
                                query_params.append("target_date_end", newDate.toISOString());
                                continue;
                            }
                            else {
                                var newEndDate = new Date(targetDateEnd.getTime() + 1000 * 60 * 60 * 24);
                                query_params.append("target_date_end", newEndDate.toISOString());
                                continue;
                            }
                        }
                        const date: Date = params.filter[f] as Date;
                        query_params.append(String(f), date.toISOString());
                    } else if (params.filter[f] !== "" && !Number.isNaN(params.filter[f])) {
                        query_params.append(String(f), String(params.filter[f]));
                    }
        }

        if (targetDate && !targetDateEnd) {
            var newDateTmp = new Date(targetDate.getTime() + 1000 * 60 * 60 * 24);
            query_params.append("target_date_end", newDateTmp.toISOString());
        }


        if (params.additional_query !== undefined) {
            for (let q of Object.keys(params.additional_query)) {
                query_params.append(q, params.additional_query[q]);
            }
        }

        return unpack<T, G>(
            await fetch(backendURL + url + (String(query_params).length > 0 ? `?${query_params}` : ""), {
                method: "GET",
                headers: { Authorization: "Bearer " + sessionStorageHelper.getToken() },
            }),
        );
    },
    delete: async (url: string) => {
        return unpack<null>(
            await fetch(backendURL + url, {
                method: "DELETE",
                headers: { Authorization: "Bearer " + sessionStorageHelper.getToken() },
            }),
        );
    },
    login: async (url: string, credentials: Credentials) => {
        let data = new URLSearchParams();
        data.append("username", credentials.login);
        data.append("password", credentials.password);

        return unpack<AuthResponse>(
            await fetch(backendURL + url, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: data,
            }),
        );
    },

    postFiles: async (url: string, files: File[]) => {
        const fileForm = new FormData();
        let totalFilesize = 0;
        files.forEach((e) => {
            totalFilesize += e.size;
            fileForm.append("files", e);
        });

        if (totalFilesize >= Number.parseInt(process.env.REACT_APP_ATTACHMENT_MAX_SIZE ?? "0")) {
            return {
                status: 413,
                ok: false,
            } as backendResponse<null, undefined>;
        }

        return unpack<string>(
            await fetch(backendURL + url, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorageHelper.getToken(),
                },
                body: fileForm,
            }),
        );
    },
};

export default backend;
