import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import styles from "./CustomDatePicker.module.css";
import "./DatePicker.css";
import moment from "moment";
import { getLocale } from "i18n";

interface localProps {
    value?: Date;
    onChange: (d: Date) => void;
    scrollToTop?: boolean;
}

export default function CustomDatePicker(props: localProps) {
    const { t } = useTranslation("common", { keyPrefix: "customDatePicker" });

    const [selectedTime, setSelectedTime] = useState<{ hour: number; minute: number }>(() => {
        var date = new Date();
        return {
            hour: props.value?.getHours() ?? date.getHours(),
            minute: props.value?.getMinutes() ?? date.getMinutes(),
        };
    });

    const now = useRef<Date>(moment(new Date()).add(15, "m").toDate());

    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = Array.from({ length: 60 }, (_, i) => i);

    useEffect(() => {
        var date = moment(new Date()).add(15, "m").toDate();
        //document.getElementById('h_' + date.getHours())?.scrollIntoView({behavior: 'smooth', block: 'nearest'});
        //document.getElementById('m_' + date.getMinutes())?.scrollIntoView();

        document.getElementById("h_" + date.getHours())?.scrollIntoView({ block: "nearest" });
        //window.scroll({top: el?.offsetTop, behavior: 'smooth',})
        if (props.scrollToTop === true) window.scrollTo(0, 0);
    }, [props.scrollToTop]);

    const isDifferentDay = (date?: Date, other?: Date) => {
        date = date === undefined ? undefined : new Date(date);
        other = other === undefined ? undefined : new Date(other);
        let n = other ?? moment(new Date()).add(15, "m").toDate();
        if (date)
            return (
                date.getDate() > n.getDate() || date.getMonth() > n.getMonth() || date.getFullYear() > n.getFullYear()
            );
        return false;
    };

    const different_day = useRef(isDifferentDay(props.value));

    return (
        <div className={styles.content}>
            <label className={styles.main_label}>{t("targetDate")}</label>
            <div className={styles.calendar_wrapper}>
                <DatePicker
                    selected={props.value}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => {
                        if (date) {
                            let tmpDate = date;
                            let n = moment(new Date()).add(15, "m").toDate();
                            different_day.current = isDifferentDay(date, n);

                            /* if(!different_day.current){
                                if(selectedTime.hour < n.getHours())
                                {
                                    if(selectedTime.minute < n.getMinutes()){
                                        setSelectedTime({minute: n.getMinutes(), hour: n.getHours()});
                                    }else{
                                        setSelectedTime({...selectedTime, hour: n.getHours()});
                                    }
                                }else if(selectedTime.hour === n.getHours() && selectedTime.minute < n.getMinutes()){
                                    setSelectedTime({...selectedTime, minute: n.getMinutes()});
                                }
                            } */

                            now.current = n;

                            if (selectedTime.hour !== 0 && selectedTime.minute !== 0) {
                                tmpDate?.setHours(selectedTime.hour);
                                tmpDate?.setMinutes(selectedTime.minute);
                            }
                            props.onChange(tmpDate);
                        }
                    }}
                    minDate={now.current}
                    locale={getLocale()}
                    inline
                    adjustDateOnChange
                />
                <div className={styles.time_wrapper}>
                    <div className={styles.time_label}>
                        <b>{t("hour")}</b> <br />
                        <br />
                        {`${String(selectedTime.hour).padStart(2, "0")}:${String(selectedTime.minute).padStart(2, "0")}`}
                    </div>

                    <div className={styles.time_list_wrapper}>
                        <div className={styles.time_list}>
                            <ul>
                                {hours.map((e) => {
                                    const available = e >= now.current.getHours() || different_day.current;
                                    return (
                                        <li
                                            key={e}
                                            id={"h_" + e}
                                            className={
                                                available
                                                    ? selectedTime.hour === e
                                                        ? `${styles.selected_li} ${styles.time_hour_li}`
                                                        : styles.time_hour_li
                                                    : `${styles.time_hour_li} ${styles.time_disabled}`
                                            }
                                            onClick={() => {
                                                if (available) {
                                                    let m = selectedTime.minute;

                                                    if (now.current.getHours() === e && m < now.current.getMinutes()) {
                                                        m = now.current.getMinutes();
                                                    }

                                                    setSelectedTime({ hour: e, minute: m });
                                                    let tmpDate = props.value ?? new Date();
                                                    tmpDate?.setHours(e);
                                                    tmpDate?.setMinutes(m);
                                                    props.onChange(tmpDate);
                                                }
                                            }}
                                        >
                                            {e}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>

                        <div id={styles.minute_list} className={styles.time_list}>
                            <ul>
                                {minutes.map((e) => {
                                    const available =
                                        selectedTime.hour > now.current.getHours() ||
                                        (selectedTime.hour === now.current.getHours() &&
                                            e >= now.current.getMinutes()) ||
                                        different_day.current;
                                    return (
                                        <li
                                            key={e}
                                            id={"m_" + e}
                                            className={
                                                available
                                                    ? selectedTime.minute === e
                                                        ? `${styles.selected_li} ${styles.time_minute_li}`
                                                        : styles.time_minute_li
                                                    : `${styles.time_minute_li} ${styles.time_disabled}`
                                            }
                                            onClick={() => {
                                                if (available) {
                                                    setSelectedTime({ ...selectedTime, minute: e });

                                                    let tmpDate = props.value ?? new Date();
                                                    tmpDate?.setHours(selectedTime.hour);
                                                    tmpDate?.setMinutes(e);
                                                    props.onChange(tmpDate);
                                                }
                                            }}
                                        >
                                            {e}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
