import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./singleInputWithClear.module.css";

export default function SingleInputWithClear(props: {
    value: string; // Main value for input
    placeholder: string;
    htmlId: string;
    setValue: (value: string) => void;
    setOnClear?: () => void;

    label?: string;
}) {
    return (
        <div className={styles.wrapper}>
            {props.label && props.label !== "" && (
                <label className={styles.label} htmlFor={props.htmlId} title={props.label}>
                    {props.label}
                </label>
            )}
            <div className={styles.container}>
                <input
                    id={props.htmlId}
                    className={styles.input}
                    type="text"
                    value={props.value}
                    placeholder={props.placeholder}
                    autoComplete="off"
                    title="test"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.setValue(e.currentTarget.value);
                    }}
                ></input>
                <div
                    className={props.value === "" ? styles.clear_button_disabled : styles.clear_button}
                    onClick={() => {
                        props.setValue("");
                        if (props.setOnClear) props.setOnClear();
                    }}
                >
                    <FontAwesomeIcon
                        className={props.value === "" ? styles.icon_disabled : styles.icon}
                        icon={faXmark}
                    />
                </div>
            </div>
        </div>
    );
}
