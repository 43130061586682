import { useTranslation } from "react-i18next";
import { ListType } from "../ListInput/ListInput";
import { useErrorHandling } from "commons/hooks/useErrorHandling";
import { useEffect, useRef, useState } from "react";
import { WorkerTypeList } from "api/entities/enums";
import localStyles from "../ListInput/ListInput.module.css";
import commonStyles from "commons/Registration/Registration.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface CheckboxListInputProps {
    setInputs: (id: string, name: string, values?: string[]) => void; // funkcja ustawiająca id i nazwe pola
    labelText: string;
    id: string; // id can be anything, except other html input ids in a form
    mode: ListType; // Jaki typ danych ma wyświetlić lista
    auditor_co_id?: string; // Do pobierania listy rewidentów danej firmy
    contract_id?: string; // do pobierania usług dostępnych do dodania do kontraktu
    resetElement?: boolean;
    updateElement?: boolean;
    onElementClear?: () => void; // dodatkowa funkcja wywoływana przy kliknięciu x, np. do czyszczenia
    onAuditorClick?: (auditorId: string) => void;
    values?: string[];
}

export const CheckboxListInput = (props: CheckboxListInputProps) => {
    const { handleErrors } = useErrorHandling();
    const et = useTranslation("common", { keyPrefix: "workerTypes" });

    const [isFetched, setIsFetched] = useState(false);
    const fetchedData = useRef<Object[]>([]);

    // Stores value for main input
    const [values, setValues] = useState<string[]>([]);

    //For Csstransition
    const listRef = useRef(null);

    const handleItemClick = (id: string, name: string) => {
        let newValues: string[] = [];
        if (values.includes(id)) {
            newValues = values.filter((d) => d !== id);
        } else {
            newValues = values.concat(id);
        }
        props.setInputs("", "", newValues);
        setValues(newValues);
        if (props.onAuditorClick) props.onAuditorClick(id);
    };

    const loaded = useRef(false);
    useEffect(() => {
        if (props.values) {
            setValues(props.values);
        }

        // eslint-disable-next-line
    }, []);

    // Resets component
    useEffect(() => {
        if (loaded.current === false) loaded.current = true;
        else {
            setValues([]);
        }
    }, [props.resetElement]);

    useEffect(() => {
        if (loaded.current === true) {
            if (props.values) {
                setValues(props.values);
            }
        }
        // eslint-disable-next-line
    }, [props.updateElement]);

    useEffect(() => {
        if (!isFetched)
            switch (props.mode) {
                case ListType.WorkerType: {
                    fetchedData.current = WorkerTypeList;
                    setIsFetched(true);
                    break;
                }
            }
    }, [props.mode, props.auditor_co_id, props.contract_id, isFetched, handleErrors]);

    return (
        <div style={{ padding: "0 10px 0 10px" }} ref={listRef} className={localStyles.list_wrapper}>
            {props.labelText !== "" && (
                <label className={commonStyles.input_label} title={props.labelText}>
                    {props.labelText}
                </label>
            )}
            <div className={localStyles.list}>
                <MapData
                    mode={props.mode}
                    data={fetchedData.current}
                    handleClick={handleItemClick}
                    values={values}
                    et={et.t}
                />
            </div>
        </div>
    );
};

interface MapDataProps {
    mode: ListType;
    data: Object[];
    handleClick: (id: string, name: string) => void;
    values: string[];
    et: any;
}

function MapData(props: MapDataProps) {
    const { t } = useTranslation("common", { keyPrefix: "workerTypes" });

    if (props.mode === ListType.WorkerType)
        return (
            <>
                {(props.data as WorkerType[]).map((data) => {
                    return (
                        <div
                            onClick={() => props.handleClick(data, t(`${data}`))}
                            className={localStyles.chexbox_list_item}
                            key={data}
                        >
                            {t(`${data}`)}
                            {props.values.includes(data) && (
                                <div className={localStyles.button_small}>
                                    <FontAwesomeIcon className={localStyles.icon} icon={faCheck} />
                                </div>
                            )}
                        </div>
                    );
                })}
            </>
        );
    else {
        return <></>;
    }
}

export default CheckboxListInput;
