import backend, { QueryParams } from "api/backend";
import { PriceWithService } from "api/entities/price";
import { PriceBase, PriceCreate, PriceEdit } from "api/entities/bases/price";
import { PriceFilter } from "api/filters/price";

/**
 *  ## Object containing functions that fetch API endpoints related to prices for services in contracts
 *  ### **_priceApiService_** functions:
        fetchPrices:
            (q?: QueryParams<PriceWithService[], PriceFilter>)
                => Promise<backendResponse<PriceWithService[], PriceFilter>>
    <br/>
        fetchPricesByContract:
            (contract_id: string [UUID], q?: QueryParams<PriceWithService[], PriceFilter>)
                => Promise<backendResponse<PriceWithService[], PriceFilter>>
    <br/>
        fetchPricesByContractEditable:
            (contract_id: string [UUID], q?: QueryParams<PriceWithService[], PriceFilter>)
                => Promise<backendResponse<PriceWithService[], PriceFilter>>
    <br/>
        fetchPricesByContractRemovable:
            (contract_id: string [UUID], q?: QueryParams<PriceWithService[], PriceFilter>)
                => Promise<backendResponse<PriceWithService[], PriceFilter>>
    <br/>
        fetchPricesByService:
            (service_id: string [UUID], q?: QueryParams<PriceWithService[], undefined>)
                => Promise<backendResponse<PriceWithService[], undefined>>
    <br/>
        fetchPrice:
            (contract_id: string [UUID], service_id: string [UUID])
                => Promise<backendResponse<PriceWithService, undefined>>
    <br/>
        registerPrice:
            (price: PriceCreate)
                => Promise<backendResponse<PriceBase, undefined>>
    <br/>
        removePrice:
            (contract_id: string [UUID], service_id: string [UUID])
                => Promise<backendResponse<null, undefined>>
    <br/>
        editPrice: 
            (contract_id: string [UUID], service_id: string [UUID], edit: PriceEdit)
                => Promise<backendResponse<PriceBase, undefined>>
 */
export const priceApiService = {
    fetchPrices: (q?: QueryParams<PriceWithService[], PriceFilter>) => {
        return backend.get(`/prices`, q);
    },
    fetchPricesByContract: (contract_id: string, q?: QueryParams<PriceWithService[], PriceFilter>) => {
        return backend.get(`/prices/contract/${contract_id}`, q);
    },
    fetchPricesByContractEditable: (contract_id: string, q?: QueryParams<PriceWithService[], PriceFilter>) => {
        return backend.get(`/prices/contract/${contract_id}/editable`, q);
    },
    fetchPricesByContractRemovable: (contract_id: string, q?: QueryParams<PriceWithService[], PriceFilter>) => {
        return backend.get(`/prices/contract/${contract_id}/removable`, q);
    },
    fetchPricesByService: (service_id: string, q?: QueryParams<PriceWithService[]>) => {
        return backend.get(`/prices/service/${service_id}`, q);
    },
    fetchPrice: (contract_id: string, service_id: string) => {
        return backend.get<PriceWithService>(`/price/${contract_id}/${service_id}`);
    },
    registerPrice: (price: PriceCreate) => {
        return backend.post<PriceBase>(`/price`, price);
    },
    removePrice: (contract_id: string, service_id: string) => {
        return backend.delete(`/price/${contract_id}/${service_id}`);
    },
    editPrice: (contract_id: string, service_id: string, edit: PriceEdit) => {
        return backend.put<PriceBase>(`/price/${contract_id}/${service_id}`, edit);
    },
};
