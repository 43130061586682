import api from "api";
import { OfferCreate, OfferEdit } from "api/entities/bases/offer";
import { CommuteMode } from "api/entities/enums";

const getDefaultFormData = (auditorManagerId?: string, commissionId?: string, contract_id?: string): OfferCreate => {
    return {
        commission_id: commissionId ?? "",
        contract_id: contract_id ?? "",
        auditor_mngr_id: auditorManagerId ?? "",
        auditor_id: "",

        commute_mode: CommuteMode.none,

        addr_name: "",
        addr_latitude: 0.0,
        addr_longitude: 0.0,
        expiration_date: undefined,
    };
};

const handleSubmit = async (o: OfferCreate, f: File[]) => {
    const x = await api.registerOffer(o);

    if (x.ok && f.length > 0) {
        await api.registerOfferAttachment(f, x.data.id);
    }

    return x;
};

const handleEditSubmit = async (id: string, o: OfferEdit) => {
    return await api.editOffer(id, o);
};

const newOfferService = {
    handleSubmit,
    getDefaultFormData,
    handleEditSubmit,
};

export { newOfferService };
