import { useEffect, useRef, useState } from "react";
import i18n from "i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmerica } from "@fortawesome/free-solid-svg-icons";
import styles from "./LanguageMenu.module.css";

// type: css style align
// currently supports:
// left, center
export default function LanguageMenu(props: { type: string }) {
    const [langMenu, setLangMenu] = useState(false);
    const handleLngChange = (langShort: string) => {
        i18n.changeLanguage(langShort);
    };

    const menuCssType = useRef(styles.languages_left);

    useEffect(() => {
        if (props.type === "center") menuCssType.current = styles.languages_center;
    }, [props.type]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.menu} onClick={() => setLangMenu(!langMenu)}>
                <div className={styles.icon_wrapper}>
                    <FontAwesomeIcon className={langMenu ? styles.icon_selected : styles.icon} icon={faEarthAmerica} />
                </div>
                {langMenu && (
                    <div className={menuCssType.current}>
                        <div
                            className={styles.langSelect}
                            onClick={() => {
                                handleLngChange("pl");
                            }}
                        >
                            <span>Polski</span>
                        </div>
                        <div
                            className={styles.langSelect}
                            onClick={() => {
                                handleLngChange("en");
                            }}
                        >
                            <span>English</span>
                        </div>
                        <div
                            className={styles.langSelect}
                            onClick={() => {
                                handleLngChange("de");
                            }}
                        >
                            <span>Deutsch</span>
                        </div>
                        <div
                            className={styles.langSelect}
                            onClick={() => {
                                handleLngChange("pt");
                            }}
                        >
                            <span>Português</span>
                        </div>
                        <div
                            className={styles.langSelect}
                            onClick={() => {
                                handleLngChange("cs");
                            }}
                        >
                            <span>Čeština</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
