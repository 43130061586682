import { ReactNode } from "react";
import Sidenav, { navWidth } from "commons/Sidenav";
import Header from "commons/Header";
import styles from "./AdminView.module.css";
import { routesHelper } from "routes/routesHelper";
const railyLogo = require("assets/marketplace_logo.png");
const railyLogoSmall = require("assets/marketplace_logo_small.png");

export default function AdminView(props: { children?: ReactNode }) {
    return (
        <div className={styles.app}>
            <Sidenav
                items={routesHelper.getAdminRoutes().map((item) => {
                    return { url: item.path, icon: item.icon, name: item.name };
                })}
            />
            <div style={{ width: "100%", paddingLeft: navWidth }}>
                <Header>
                    <div className={styles.logo_wrapper}>
                        <picture>
                            <source media="(max-width: 700px)" srcSet={railyLogoSmall} />
                            <source srcSet={railyLogo} />
                            <img className={styles.logo} src={railyLogo} alt="Logo" />
                        </picture>
                    </div>
                </Header>
                <div className={styles.content_wrapper}>
                    <div className={styles.content}>{props.children}</div>
                </div>
            </div>
        </div>
    );
}
