import api from "api";
import { backendResponse } from "api/backend";
import { CommissionBase, CommissionCreate } from "api/entities/bases/commission";
import moment from "moment";

/* Used in 
    DispatcherView:
        NewCommission    
*/

type Commission = {
    addr_primary_id: string;
    addr_primary_name: string;
    addr_primary_latitude: number;
    addr_primary_longitude: number;

    addr_secondary_id: string;
    addr_secondary_name: string;
    addr_secondary_latitude: number;
    addr_secondary_longitude: number;

    dispatcher_id: string;
    target_date: Date;
    service_id: string;
    service_name: string;
    train_no: string;
    contact_person: string;
    contact_phone: string;
    locomotive: string;

    commission_id?: string;
};

const getDefaultFormData = (dispatcher_id?: string): Commission => {
    return {
        addr_primary_id: "",
        addr_primary_name: "",
        addr_primary_latitude: 0.0,
        addr_primary_longitude: 0.0,

        addr_secondary_id: "",
        addr_secondary_name: "",
        addr_secondary_latitude: 0.0,
        addr_secondary_longitude: 0.0,

        dispatcher_id: dispatcher_id ?? "",
        target_date: moment(new Date()).add(15, "m").toDate(),
        service_id: "",
        service_name: "",
        train_no: "",
        contact_person: "",
        contact_phone: "",
        locomotive: "",
    };
};

const getClearAddrData = (c: Commission): Commission => {
    return {
        ...c,
        addr_primary_id: "",
        addr_primary_name: "",
        addr_primary_latitude: 0.0,
        addr_primary_longitude: 0.0,

        addr_secondary_id: "",
        addr_secondary_name: "",
        addr_secondary_latitude: 0.0,
        addr_secondary_longitude: 0.0,

        target_date: new Date(),
        service_id: "",
        service_name: "",
        train_no: "",
        contact_person: "",
        contact_phone: "",
        locomotive: "",
    };
};

const handleSubmit = async (c: Commission): Promise<backendResponse<CommissionBase>> => {
    /* Functions delivering data should ensure that if address is selected - name is empty
        same when creating new address - id is empty */

    let com = {
        addr_primary:
            c.addr_primary_id === ""
                ? {
                      name: c.addr_primary_name,
                      latitude: c.addr_primary_latitude,
                      longitude: c.addr_primary_longitude,
                  }
                : c.addr_primary_id,

        addr_secondary:
            c.addr_secondary_id === ""
                ? c.addr_secondary_name !== ""
                    ? {
                          name: c.addr_secondary_name,
                          latitude: c.addr_secondary_latitude,
                          longitude: c.addr_secondary_longitude,
                      }
                    : undefined
                : c.addr_secondary_id,

        target_date: c.target_date,
        dispatcher_id: c.dispatcher_id,
        train_no: c.train_no,
        contact_person: c.contact_person,
        contact_phone: c.contact_phone,
        locomotive: c.locomotive,
    } as CommissionCreate;

    return await api.editCommission(c.commission_id ?? "", com);
};

const editCommissionService = {
    getDefaultFormData,
    getClearAddrData,
    handleSubmit,
};

export { editCommissionService, Commission };
