import { HTTPErrorType } from "api/entities/error";
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const useErrorHandling = () => {
    const [error, setError] = useState<HTTPErrorType | undefined>();
    const { t } = useTranslation("errors");

    const handleErrors = useCallback(
        (e?: HTTPErrorType, alert?: boolean): { ok: boolean; err_str: string; params?: string[] } => {
            if (e === undefined) return { ok: true, err_str: "" };

            if (alert === undefined) alert = true;

            if (typeof e === "string") {
                // string detail ** NEEDS TRANSLATIONS
                if (e !== "") {
                    return { ok: false, err_str: e };
                }
            } else if (Array.isArray(e)) {
                // validation errors ** NEEDS TRANSLATIONS
                const msg = e.map((err) => err.msg).join("\n");
                return { ok: false, err_str: msg };
            } else {
                // API error
                return { ok: false, err_str: t(e.err_str), params: e.params };
            }

            return { ok: false, err_str: t("err_unknown") };
        },
        [t],
    );

    useEffect(() => {
        if (!handleErrors(error).ok) setError(undefined);
    }, [error, handleErrors]);

    return { setError, handleErrors };
};
